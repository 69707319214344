import config from "@/config";
import axios from "axios";
import * as types from "@/store/mutation-types";
import store from "@/store";
import { sendRequestErrorToSlack } from "@/services/utils";
const UUID_LENGTH = 36;

const getClusterToken = (establishment, secondTry, essentialBearer) => {
  return new Promise((resolve, reject) => {
    const url = "/api/auth/cluster/" + establishment;

    axios.get(url).then(
      response => {
        if (response?.data?.data?.access_token) {
          const responseData = response.data.data;
          store.commit(types.TOKEN_API_CLUSTER, responseData.access_token);
          store.commit(types.TOKEN_REFRESH_API_CLUSTER, responseData.refresh_token);

          config.clusterApiCredentials.bearer = responseData.access_token;

          if (responseData.bot) {
            config.botApiCredentials.bearer = responseData.bot;
            store.commit(types.SET_BOT_TOKEN, config.botApiCredentials.bearer);
          }
          if (responseData.cms) {
            config.cmsApiHostCredentials.bearer = responseData.cms;
            store.commit(types.SET_CMS_TOKEN, config.cmsApiHostCredentials.bearer);
          }

          if (establishment?.length === UUID_LENGTH) {
            responseData.establishmentPublicId = establishment;
            resolve(responseData);
          } else if (store.state.establishmentPublicId?.[establishment]) {
            responseData.establishmentPublicId = store.state.establishmentPublicId[establishment];
            resolve(responseData);
          } else {
            axios({
              method: "get",
              url: config.clusterHost + "/establishment/encryptId/" + establishment,
              headers: {
                Authorization: "Bearer " + config.clusterApiCredentials.bearer
              }
            }).then(
              responsePublicId => {
                if (responsePublicId?.data?.payload) {
                  store.commit(types.SET_ESTABLISHMENT_PUBLIC_ID, {
                    establishmentPublicId: responsePublicId.data.payload,
                    establishmentEncryptId: establishment
                  });
                }
                responseData.establishmentPublicId = responsePublicId.data.payload;
                resolve(responseData);
              },
              err => {
                resolve(responseData);
              }
            );
          }
        } else {
          if (url === "/api/auth/cluster/" + establishment) {
            sendRequestErrorToSlack(response); // to pwa channel
          }
          reject();
        }
      },
      err => {
        if (url === "/api/auth/cluster/" + establishment) {
          sendRequestErrorToSlack(err.response); // to pwa channel
        }
        reject(err);
      }
    );
  });
};
function parseErrorToSlack(err, type) {
  if (err) {
    const errObject = JSON.parse(JSON.stringify(err));
    if (errObject) {
      let statusCode = "";
      if (errObject.status) {
        statusCode = errObject.status;
      } else {
        statusCode = errObject.message;
      }
      if (errObject.message != "Network Error") {
        if (statusCode.includes("status code") && statusCode.indexOf("status code") == statusCode.length - 15) {
          statusCode = statusCode.substring(statusCode.length - 3, statusCode.length);
        }
        // "ERROR TEST CMS: " +
        const slackInfo = {
          status: statusCode,
          config: {
            method: errObject.config?.method,
            baseUrl: "",
            url: errObject.config?.url
          },
          data: {
            errors: ""
          }
        };
        if (errObject.data?.errors) {
          slackInfo.data.errors = errObject.data?.errors;
        } else {
          let errData = "";
          if (errObject.data) {
            errData = errObject.data;
          }
          slackInfo.data.errors = errObject.name + ": " + errObject.message + ". " + errObject.stack + ". " + errData;
        }
        sendRequestErrorToSlack(slackInfo, type);
      }
    }
  }
}

function getAuthBearer(state) {
  let bearer = "";
  if (config.clusterApiCredentials.bearer) {
    bearer = config.clusterApiCredentials.bearer;
  } else {
    if (state.tokenApiCluster) {
      bearer = state.tokenApiCluster;
    } else {
      bearer = config.cmsApiHostCredentials.bearer;
    }
  }

  return bearer;
}

export { getClusterToken, getAuthBearer };
