export default [
  { id: 1, name: "Arabic", iso: "ar" },
  { id: 7, name: "English", iso: "en" },
  { id: 8, name: "Spanish", iso: "es" },
  { id: 9, name: "French", iso: "fr" },
  { id: 1, name: "Bulgarian", iso: "bg" },
  { id: 2, name: "Catalan", iso: "ca" },
  { id: 3, name: "Czech", iso: "cs" },
  { id: 4, name: "Dansk", iso: "da" },
  { id: 5, name: "German", iso: "de" },
  { id: 6, name: "Greek", iso: "el" },
  { id: 10, name: "Hebrew", iso: "he" },
  { id: 11, name: "Hungarian", iso: "hu" },
  { id: 12, name: "Indonesian", iso: "id" },
  { id: 13, name: "Italian", iso: "it" },
  { id: 14, name: "Japanese", iso: "ja" },
  { id: 15, name: "Korean", iso: "ko" },
  { id: 16, name: "Dutch", iso: "nl" },
  { id: 17, name: "Norwegian", iso: "no" },
  { id: 18, name: "Portuguese", iso: "pt" },
  { id: 19, name: "Russian", iso: "ru" },
  { id: 20, name: "Turkish", iso: "tr" },
  { id: 21, name: "Vietnamese", iso: "vi" },
  { id: 22, name: "Chinese (zh - simplified)", iso: "zh" },
  { id: 23, name: "Polish", iso: "pl" },
  { id: 24, name: "Romanian", iso: "ro" },
  { id: 25, name: "Slovak", iso: "sk" },
  { id: 26, name: "Swedish", iso: "sv" },
  { id: 27, name: "Georgian", iso: "ka" },
  { id: 28, name: "Slovenian", iso: "sl" },
  { id: 29, name: "Finnish", iso: "fi" },
  { id: 30, name: "Thai", iso: "th" },
  { id: 31, name: "Croatian", iso: "hr" },
  { id: 32, name: "Chinese traditional", iso: "zh-TW" },
  { id: 33, name: "Chinese simplified", iso: "zh-CN" },
  { id: 34, name: "Estonian", iso: "et" },
  { id: 35, name: "Latvian", iso: "lv" },
  { id: 36, name: "Hindi", iso: "hi" }
];
