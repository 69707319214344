import config from "../config";
import request from "@/services/request";
import * as auth from "./auth";
import { isEmbedPWA } from "../services/utils";

const createVisitor = (state, success, fail, data = {}) => {
  const language = data?.locale === "es" ? data?.locale : window.navigator.language.substring(0, 2);

  let country = "ES";
  if (window.navigator.language.substring(3, 5).length === 2) {
    country = window.navigator.language.substring(3, 5);
  }
  if (data && state.pushToken) {
    data.pushToken = state.pushToken;
  }

  let version = "1.0";
  if (isEmbedPWA()) {
    const ua = window.navigator.userAgent.toLowerCase();
    const isIos = /iphone|ipod|ipad|iOS/i.test(ua);
    version = isIos ? "embed_ios" : "embed_android";
  }

  const urlParams = new URLSearchParams();
  ["utm_source", "utm_medium", "utm_campaign"].forEach(param =>
    state[param] ? urlParams.append(param, state[param]) : null
  );

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${auth.getAuthBearer(state)}`
  };

  if (state.user?.userKey) {
    headers["Stay-Authorization"] = state.user.userKey;
  }

  if (state.user?.deviceKey) {
    data.deviceKey = state.user.deviceKey;
  }

  const pushToken =
    state.notificationsModule.isGranted === "granted" ? (state.pushToken ?? state.deviceUid) : undefined;

  request({
    method: "post",
    url: `${config.clusterHost}/guest/authentication?${urlParams?.toString()}`,
    headers: headers,
    data: {
      country: country,
      os: "web",
      version: version,
      model: "pwa",
      language: language,
      deviceUid: state.deviceUid,
      pushToken: pushToken,
      UTM: data?.utm ?? undefined,
      establishment: state.establishment.publicId,
      apiKey: config.apiKey
    }
  })
    .then(success)
    .catch(fail);
};

const getUserData = (state, success, fail) => {
  let url = `${config.mobileApiHost}/v2/users/info?establishment=${state.establishment.publicId}apikey=${config.apiKey}`;

  if (state.user?.userKey && state.user?.deviceKey) {
    url += `&userkey=${state.user.userKey}&devicekey=${state.user.deviceKey}`;
  }
  if (state.utm_source) {
    url += "&utm_source=" + state.utm_source;
  }
  if (state.utm_medium) {
    url += "&utm_medium=" + state.utm_medium;
  }
  if (state.utm_campaign) {
    url += "&utm_campaign=" + state.utm_campaign;
  }

  request({
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json"
    },
    data: {},
    cache: false
  })
    .then(success)
    .catch(fail);
};

const createGuestAutologin = (state, success, fail, data = {}) => {
  let room = state.user.room;
  let completeName = state.user.name;
  let checkIn = state.user.checkIn;
  let checkOut = state.user.checkOut;
  let utm = undefined;

  if (data) {
    if (data.room) {
      room = data.room;
    }
    if (data.completeName) {
      completeName = data.completeName;
    }
    if (data.checkIn) {
      checkIn = data.checkIn;
    }
    if (data.checkOut) {
      checkOut = data.checkOut;
    }
    if (data.utm) {
      utm = data.utm;
    }
  }
  if (data && state.pushToken) {
    data.pushToken = state.pushToken;
  }

  const language = window.navigator.language.substring(0, 2);
  let country = "ES";
  if (window.navigator.language.substring(3, 5).length === 2) {
    country = window.navigator.language.substring(3, 5);
  }

  let version = "1.0";
  if (isEmbedPWA()) {
    const ua = window.navigator.userAgent.toLowerCase();
    const isIos = /iphone|ipod|ipad|iOS/i.test(ua);
    version = isIos ? "embed_ios" : "embed_android";
  }

  const d = {
    country: country,
    os: "web",
    version: version,
    model: "pwa",
    language: language,
    deviceUid: state.deviceUid,
    pushToken: data && data.pushToken ? data.pushToken : state.deviceUid,
    room: room,
    completeName: completeName,
    checkIn: checkIn,
    checkOut: checkOut,
    UTM: utm
  };
  if (isEmbedPWA()) {
    const ua = window.navigator.userAgent.toLowerCase();
    const isIos = /iphone|ipod|ipad/i.test(ua);
    d.version = isIos ? "embed_ios" : "embed_android";
  }
  let url =
    config.mobileApiHost +
    "/v1/users/autologin?" +
    "establishment=" +
    state.establishment.id +
    "&apikey=" +
    config.apiKey +
    (state.user && state.user.userKey && state.user.deviceKey
      ? "&userkey=" + state.user.userKey + "&devicekey=" + state.user.deviceKey
      : "");
  if (state.utm_source) {
    url += "&utm_source=" + state.utm_source;
  }
  if (state.utm_medium) {
    url += "&utm_medium=" + state.utm_medium;
  }
  if (state.utm_campaign) {
    url += "&utm_campaign=" + state.utm_campaign;
  }
  request({
    method: "post",
    url: url,
    headers: {
      "Content-Type": "application/json"
    },
    data: d
  })
    .then(success)
    .catch(fail);
};

const createGuestReferral = (state, success, fail, data = {}) => {
  let language = window.navigator.language.substring(0, 2);
  if (data && data.locale === "es") {
    language = data.locale;
  }
  let country = "ES";
  if (window.navigator.language.substring(3, 5).length === 2) {
    country = window.navigator.language.substring(3, 5);
  }

  let version = "1.0";
  if (isEmbedPWA()) {
    const ua = window.navigator.userAgent.toLowerCase();
    const isIos = /iphone|ipod|ipad/i.test(ua);
    version = isIos ? "embed_ios" : "embed_android";
  }

  let url =
    config.mobileApiHost +
    "/v1/users/bot/facebook/autologin?" +
    "establishment=" +
    state.establishment.id +
    "&apikey=" +
    config.apiKey +
    (state.user && state.user.userKey && state.user.deviceKey
      ? "&userkey=" + state.user.userKey + "&devicekey=" + state.user.deviceKey
      : "");
  if (state.utm_source) {
    url += "&utm_source=" + state.utm_source;
  }
  if (state.utm_medium) {
    url += "&utm_medium=" + state.utm_medium;
  }
  if (state.utm_campaign) {
    url += "&utm_campaign=" + state.utm_campaign;
  }

  request({
    method: "post",
    url: url,
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      psId: state.deviceUid,
      pushToken: state.pushToken,
      preStay: data.linkId,
      os: "web",
      model: "pwa",
      UTM: data && data.utm ? data.utm : undefined,
      country: country,
      language: language,
      version: version
    }
  })
    .then(success)
    .catch(fail);
};

const createGuestReferralWithPS = (state, success, fail, data = {}) => {
  let language = window.navigator.language.substring(0, 2);
  if (data && data.locale === "es") {
    language = data.locale;
  }
  let country = "ES";
  if (window.navigator.language.substring(3, 5).length === 2) {
    country = window.navigator.language.substring(3, 5);
  }

  let version = "1.0";
  if (isEmbedPWA()) {
    const ua = window.navigator.userAgent.toLowerCase();
    const isIos = /iphone|ipod|ipad/i.test(ua);
    version = isIos ? "embed_ios" : "embed_android";
  }

  const urlVisitor = config.clusterHost + "/guest/authentication";

  const urlParams = new URLSearchParams();
  const paramsArray = ["utm_source", "utm_medium", "utm_campaign"];
  paramsArray?.forEach(param => {
    if (state[param]) {
      urlParams.append(param, state[param]);
    }
  });
  const url = `${urlVisitor}?${urlParams?.toString()}`;

  const pushToken = data && data.pushToken ? data.pushToken : state.deviceUid;

  const bearer = auth.getAuthBearer(state);
  request({
    method: "post",
    url: url,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearer
    },
    data: {
      deviceUid: state.deviceUid,
      apiKey: config.apiKey,
      pushToken: pushToken,
      prestay: data.preStay,
      os: "web",
      model: "pwa",
      UTM: data && data.utm ? data.utm : undefined,
      country: country,
      language: language,
      version: version
    }
  })
    .then(success)
    .catch(fail);
};

const createGuestWithAccessGParam = (state, success, fail, data = {}) => {
  let language = window.navigator.language.substring(0, 2);
  if (data && data.locale === "es") {
    language = data.locale;
  }
  let country = "ES";
  if (window.navigator.language.substring(3, 5).length === 2) {
    country = window.navigator.language.substring(3, 5);
  }

  let version = "1.0";
  if (isEmbedPWA()) {
    const ua = window.navigator.userAgent.toLowerCase();
    const isIos = /iphone|ipod|ipad/i.test(ua);
    version = isIos ? "embed_ios" : "embed_android";
  }

  const urlVisitor = config.clusterHost + "/guest/authentication";

  const urlParams = new URLSearchParams();
  const paramsArray = ["utm_source", "utm_medium", "utm_campaign"];
  paramsArray?.forEach(param => {
    if (state[param]) {
      urlParams.append(param, state[param]);
    }
  });
  const url = `${urlVisitor}?${urlParams?.toString()}`;

  const pushToken = data && data.pushToken ? data.pushToken : state.deviceUid;

  const bearer = auth.getAuthBearer(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + bearer
  };
  if (state.user?.userKey) {
    headers["Stay-Authorization"] = state.user.userKey;
  }
  request({
    method: "post",
    url: url,
    headers: headers,
    data: {
      deviceUid: state.deviceUid,
      apiKey: data.apiKey ?? config.apiKey,
      pushToken: pushToken,
      bookingPayload: data.bookingPayload,
      establishment: String(state.establishment.id),
      keyId: data.keyId,
      os: "web",
      model: "pwa",
      UTM: data && data.utm ? data.utm : undefined,
      country: country,
      language: language,
      version: version
    }
  })
    .then(success)
    .catch(fail);
};

const logout = (state, success, fail) => {
  request({
    method: "post",
    url: config.mobileApiHost + "/v1/users/logoutclient",
    headers: {
      "Content-Type": "application/json"
    },
    data: {},
    params: {
      apikey: config.apiKey,
      userkey: state.user.userKey,
      devicekey: state.user.deviceKey,
      establishment: state.establishment.publicId
    }
  })
    .then(success)
    .catch(fail);
};

const updateUserData = (state, success, fail, data) => {
  request({
    method: "post",
    url: config.mobileApiHost + "/v1/users/update",
    headers: {
      "Content-Type": "application/json",
      "Stay-Api-Version": "2"
    },
    data: { name: data },
    params: {
      apikey: config.apiKey,
      userkey: state.user.userKey,
      devicekey: state.user.deviceKey,
      establishment: state.establishment.publicId,
      utm_source: state.utm_source ? state.utm_source : undefined,
      utm_campaign: state.utm_campaign ? state.utm_campaign : undefined,
      utm_medium: state.utm_medium ? state.utm_medium : undefined
    }
  })
    .then(success)
    .catch(fail);
};

const getUserInbox = (state, success, fail) => {
  request({
    method: "post",
    url: `${config.mobileApiHost}/v1/users/get`,
    headers: {
      "Content-Type": "application/json",
      "Stay-Api-Version": "2"
    },
    data: {},
    params: {
      apikey: config.apiKey,
      userkey: state.user.userKey,
      devicekey: state.user.deviceKey,
      establishment: state.establishment.publicId,
      lastupdate: state.userInbox && state.userInbox.lastUpdate ? state.userInbox.lastUpdate : 0,
      utm_source: state.utm_source ? state.utm_source : undefined,
      utm_campaign: state.utm_campaign ? state.utm_campaign : undefined,
      utm_medium: state.utm_medium ? state.utm_medium : undefined
    }
  })
    .then(success)
    .catch(fail);
};
const getActivity = (state, success, fail) => {
  const locale = state.user.locale ?? window.navigator.language;

  request({
    method: "get",
    url: `${config.mobileApiHost}/v2/users/activity-list`,
    headers: {
      "Content-Type": "application/json",
      "Stay-Api-Version": "2"
    },
    params: {
      apikey: config.apiKey,
      userkey: state.user.userKey,
      devicekey: state.user.deviceKey,
      establishment: state.establishment.publicId
    },
    cache: false
  })
    .then(success)
    .catch(fail);
};

const getFieldsLoginPms = (state, success, fail) => {
  const bearer = auth.getAuthBearer(state);

  request({
    method: "get",
    url: config.clusterHost + "/guest/login/form/" + state.establishment.publicId,
    headers: {
      Authorization: "Bearer " + bearer
    },
    cache: false
  })
    .then(success)
    .catch(fail);
};

const createLoginPMS = (state, success, fail, logingFields) => {
  const urlParams = new URLSearchParams();

  if (state.utm_source) {
    urlParams.append("utm_source", state.utm_source);
  }
  if (state.utm_medium) {
    urlParams.append("utm_medium", state.utm_medium);
  }
  if (state.utm_campaign) {
    urlParams.append("utm_campaign", state.utm_campaign);
  }

  const navigatorLanguage = window.navigator.language;
  const urlComplete = `${config.clusterHost}/guest/login/?${urlParams.toString()}`;
  const language = navigatorLanguage.substring(0, 2);
  const country = navigatorLanguage.substring(3, 5).length === 2 ? navigatorLanguage.substring(3, 5) : "ES";

  let version = "1.0";
  if (isEmbedPWA()) {
    const ua = window.navigator.userAgent.toLowerCase();
    const isIos = /iphone|ipod|ipad|iOS/i.test(ua);
    version = isIos ? "embed_ios" : "embed_android";
  }

  const body = {
    psId: state.deviceUid,
    country: country,
    os: state.os ?? "web",
    model: "pwa",
    version: version,
    language: language,
    deviceUid: state.deviceUid,
    deviceKey: state.user.deviceKey,
    establishment: state.establishment.id,
    userKey: state.user.userKey,
    apiKey: config.apiKey,
    pushToken: state.pushToken ? state.pushToken : state.deviceUid //hara falta cuando metamos autenticacion y guardemos datos en server
  };

  if (logingFields) {
    for (let i = 0; i < logingFields.length; i++) {
      if (logingFields[i].value) {
        const key = logingFields[i].ref;
        body[key] = logingFields[i].value;
      }
    }
  }

  request({
    method: "post",
    url: urlComplete,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.getAuthBearer(state)}`
    },
    data: body
  })
    .then(success)
    .catch(fail);
};

const sendStatusAlert = (state, success, fail, data) => {
  request({
    method: "post",
    url:
      config.mobileApiHost +
      ":443/v1/alerts/update/alert_queue?" +
      "apikey=" +
      config.apiKey +
      "&establishment=" +
      state.establishment.id +
      "&userkey=" +
      state.user.userKey +
      "&devicekey=" +
      state.user.deviceKey,
    headers: {
      "Content-Type": "application/json"
    },
    data: data
  })
    .then(success)
    .catch(fail);
};

const getGuestConsents = (state, success, fail, data) => {
  const urlComplete = `${config.clusterHost}/guest/consents`;

  request({
    method: "get",
    url: urlComplete,
    headers: {
      "Content-Type": "application/json",
      "Stay-Establishment": state.clusterEstablishmentId,
      Authorization: "Bearer " + auth.getAuthBearer(state),
      "Stay-Authorization": state.user.userKey
    },
    cache: false
  })
    .then(success)
    .catch(fail);
};

const updateGuestConsents = (state, success, fail, data) => {
  const urlComplete = config.clusterHost + "/guest/consents";
  request({
    method: "put",
    url: urlComplete,
    headers: {
      "Content-Type": "application/json",
      "Stay-Establishment": state.clusterEstablishmentId,
      Authorization: "Bearer " + auth.getAuthBearer(state),
      "Stay-Authorization": state.user.userKey
    },
    data: {
      gdpr: {
        privacyPolicy: data.privacyPolicy,
        termsAndConditions: data.termsAndConditions,
        commercialCommunications: data.commercialCommunications
      }
    }
  })
    .then(success)
    .catch(fail);
};

export {
  createVisitor,
  getUserData,
  createGuestAutologin,
  createGuestReferral,
  createGuestReferralWithPS,
  createGuestWithAccessGParam,
  logout,
  updateUserData,
  getUserInbox,
  getActivity,
  getFieldsLoginPms,
  createLoginPMS,
  sendStatusAlert,
  getGuestConsents,
  updateGuestConsents
};
