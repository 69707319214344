import { defineAsyncComponent } from "vue";
import { mapActions, mapState } from "vuex";
import {
  capitalized,
  checkHours,
  checkStr,
  enterToEvent,
  filterNotificationCount,
  getImageUrl,
  getItemUrl,
  getString,
  getUserStatus,
  isCmsMode,
  logBackButton,
  logChain,
  LogEstablishment,
  logGTMEvent,
  logProductType,
  logScreen,
  logUserID,
  logVirtualPage
} from "../services/utils.js";
import {
  checkIsMessage,
  checkIsObject,
  checkMessageIsClient,
  isChangeInBookingNotifiable,
  isChangeInRequestNotifiable
} from "../services/utilsRequest";

import mixin from "../services/mixin";
import spacesMixin from "../mixins/spacesMixin";
import digitalKeyMixin from "../mixins/digitalKeyMixin";
import pdfMixin from "@/mixins/pdfMixin.js";

import { checkPushPermission, showFirstPushPermission, showPushPermission } from "../serviceWorker";
import { EventBus } from "../services/eventBus";
import * as types from "../store/mutation-types";
import surveyButtonTypes from "@/components/Surveys/buttonTypes.js";
import surveyTriggerTypes from "@/components/Surveys/triggerTypes.js";
import moment from "moment-timezone";
import config from "../config";
import { navigationService } from "vue-spatialnavigation";
import { showDigitalKeysFunnel } from "../services/digitalKey.js";
import frontpageStyles from "@/utils/enums/frontpageStyles.js";
import cloneDeep from "lodash-es/cloneDeep";

const LoginForm = defineAsyncComponent(() => import("./LoginForm.vue"));
const LoginPmsForm = defineAsyncComponent(() => import("./LoginPmsForm.vue"));
const Survey = defineAsyncComponent(() => import("./Survey.vue"));
const SurveyButton = defineAsyncComponent(() => import("./Surveys/SurveyButton.vue"));
const Notification = defineAsyncComponent(() => import("./Notification.vue"));
const WelcomePopup = defineAsyncComponent(() => import("./WelcomePopup.vue"));
const ServicePopup = defineAsyncComponent(() => import("./ServicePopup.vue"));
const ServiceDetail = defineAsyncComponent(() => import("./Web.vue"));
const ProfilePopup = defineAsyncComponent(() => import("./Frontpages/Components/FrontpageProfilePopup.vue"));
const LobbyQrPopup = defineAsyncComponent(() => import("@/components/Landscape/LobbyQRPopup.vue"));
const UpsellPopup = defineAsyncComponent(() => import("@/components/UpSell/UpSellPopup.vue"));

const FRONTPAGE_STYLE_WIDGETS = 13;

const Mixin = {
  data() {
    return {
      catalogueService: null,
      msg: "Welcome to Stay",
      locale: "en",
      hasForm: false,
      hasPanel: false,
      hasInbox: false,
      confirmExitCongress: false,
      hasNotifications: false,
      hideHeader: false,
      hideNotifications: false,
      scrollTop: 0,
      bubblesNumber: 5,
      showChatWidget: false,
      showAddToHomePopup: false,
      showWelcomeMessage: false,
      showSurvey: true,
      showServicePopup: false,
      servicePopupServiceData: false,
      surveyData: undefined,
      showWelcome: false,
      showLoadingInWelcome: false,
      hasEvents: false,
      hasNewEvent: false,
      actualEvents: [],
      titleTextBackHome: "",
      hasNewPMS: true,
      showLoader: false,
      logingFields: [],
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: true,
        pageDots: true,
        wrapAround: true
      },
      errorMessage: "",
      scrollableBoxes: true, //only used by FrontpageV3
      screenNotLogged: true,
      hasPushPermission: true,
      showCongressPopup: false,
      congressRedirectIsPublic: false,
      headerCongressRedirect: "",
      showPopupError: false,
      errorPopupMessage: "",
      showCongressLoader: false,
      showFirstNotificationPopup: false,
      showServiceDetail: false,
      serviceDetailData: false,
      welcomeIsActive: false,
      frontpageVisible: true,
      hideFrontpageHeader: false,
      showWelcomeAnimation: false,
      surveyButtonTypes: surveyButtonTypes,
      surveyTriggerTypes: surveyTriggerTypes,
      showSurveyButton: false,
      poweredBy: false,
      hideLoginButton: false,
      loadingEstablishment: false,
      multipleNotifications: {},
      timer: null,
      profilePopupVisible: false,
      lobbyQrPopupVisible: false,
      upsellInfoPopup: {},
      availablePromos: [],
      originalShowSurveyButtonValue: false,
      surveyProactiveAvailable: false,
      isDigitalKeyFunnelActive: false
    };
  },
  mixins: [mixin, pdfMixin, spacesMixin, digitalKeyMixin],
  computed: {
    ...mapState("frontpage", ["data"]),
    ...mapState([
      "establishment",
      "user",
      "congressEvents",
      "userInbox",
      "strings",
      "establishmentHash",
      "notifications",
      "frontpageIcon",
      "chatMessages",
      "pendingTermsConditions",
      "newEventId",
      "hiddenSurveys",
      "isDesktop",
      "isLobby",
      "isTV",
      "welcomeSurveyResponseDate",
      "userInPreStay",
      "mySchedule",
      "myVisitorBookings",
      "congressIdRedirect",
      "apiKey",
      "appId",
      "newCongress",
      "addToHomeAlreadyShown",
      "starterPlan"
    ]),
    // showDesktopChatScreen: function() {
    //   return this.$store.state.showDesktopChatScreen;
    // },
    unreadAlertCount: function () {
      const alerts = this.userInbox ? this.userInbox.alerts : null;

      if (!alerts) {
        return 0;
      }
      const self = this;
      if (Object.values(alerts) && Object.values(alerts).length) {
        const filterUnread = Object.values(alerts).filter(alert => {
          return (
            alert.unread === 1 && alert.trigger === "now" && alert.establishment === parseInt(self.establishment.id)
          );
        });
        return filterUnread.length;
      } else {
        return 0;
      }
    },
    notificationsCount: function () {
      return filterNotificationCount(this.notifications);
    },
    bubblesWrapperClass: function () {
      const bubblesNumberArray = ["hidden", "hidden", "three", "four", "five"];
      return bubblesNumberArray[this.bubblesNumber - 1];
    },
    futureBookmarksPresent: function () {
      const now = new moment();
      const bookmarks = [];
      if (this.mySchedule && this.mySchedule[this.establishment.id]) {
        Object.keys(this.mySchedule[this.establishment.id]).forEach(key => {
          const item = this.mySchedule[this.establishment.id][key];
          const activityMoment = moment(item.eventDay + " " + item.hour);
          if (now.isBefore(activityMoment)) {
            bookmarks.push(item);
          }
        });
      }
      return bookmarks.length > 0;
    },
    hasVisitorBookings: function () {
      const now = new moment();
      const bookmarks = [];
      Object.keys(this.myVisitorBookings).forEach(key => {
        const item = this.myVisitorBookings[key];
        const bookingMoment = moment(item.bookingDate + " " + item.bookingHour);
        if (now.isBefore(bookingMoment)) {
          bookmarks.push(item);
        }
      });
      return bookmarks.length > 0;
    },
    isCmsMode() {
      isCmsMode();
    },
    notShownNotifications: function () {
      if (!this.notifications || this.notifications.length === 0) {
        return [];
      }
      const filtered = this.notifications.filter(notification => {
        return !notification.alreadyShown || notification.alreadyShown === false;
      });

      return filtered;
    },
    notificationsNowVisible: function () {
      return (
        !this.hideNotifications &&
        this.notShownNotifications &&
        this.notShownNotifications.length &&
        this.notShownNotifications.length > 0
      );
    },
    filteredUpsellNode() {
      return this.data.upsell_nodes.filter(item => (item.hasOwnProperty("enabled") ? item.enabled === true : item));
    },
    userButtonDiv() {
      if (!this.$refs["user-button"]) {
        return false;
      }
      return {
        y: this.$refs["user-button"].getBoundingClientRect().bottom,
        "margin-right": 23
      };
    },
    displayPromotionPopup() {
      return this.$store.state.promotions.displayPopup;
    }
  },
  props: ["frontpageData"],
  methods: {
    ...mapActions("frontpage", ["getFrontpage"]),
    ...mapActions([
      "getFieldsLoginPms",
      "setIsFromPWA",
      "getEstablishment",
      "getServiceCatalogues",
      "createVisitor",
      "createGuestAutologin",
      "createGuestReferralWithPS",
      "getStrings",
      "getUserInbox",
      "getActivity",
      "createGuestReferral",
      "logout",
      "getDeepLinkData",
      "getFrontpageIcon",
      "storeChatMessages",
      "getSurvey",
      "setPendingTermsConditions",
      "hideSurvey",
      "saveWelcomeSurveyResponseDate",
      "getCongress",
      "linkCongress",
      "updateUserData",
      "decodeEstablishmentHash"
    ]),
    emitShowWelcome() {
      EventBus.$emit("displayWelcomePopup", this.showWelcome);
    },
    async closeWelcomePopup() {
      this.showWelcome = false;

      EventBus.$emit("displayWelcomePopup", this.showWelcome);

      this.togglePendingTerms(false);
      this.startUserDataWithDelay(2);

      await this.getPromotions();
    },
    displaySurveyButton() {
      if (this.originalShowSurveyButtonValue) {
        this.showSurveyButton = true;
      }
    },
    showUpsellPopup() {
      this.showSurveyButton = false;
      this.$store.commit("promotions/displayPopup");
    },
    hideUpsellPopup() {
      this.$store.commit("promotions/displayPopup");
      this.displaySurveyButton();
    },
    async parsePromosToNotifications(promos) {
      if (!promos.length) {
        return;
      }

      await promos.forEach(async availablePromo => {
        const voucherNotificationFormatted = await this.$store.dispatch(
          "promotions/parseToNotification",
          availablePromo
        );
        this.availablePromos.push(voucherNotificationFormatted);
      });
    },
    displayUpsellPopupData() {
      if (this.showWelcome) {
        return;
      }

      for (const { data } of this.availablePromos) {
        if (!this.$store.getters["promotions/getSeenPromotions"].includes(data.id)) {
          this.$store.commit("promotions/addShownPromotion", data.id);

          this.upsellInfoPopup = {
            photograph: data.image,
            translatableDescription: data.description,
            translatableTitle: data.title,
            translatableSubtitle: {},
            notification: data.data
          };

          this.showUpsellPopup();
          break;
        }
      }
    },
    closePopupServiceDetail() {
      this.scrollableBoxes = true;
      this.showWelcomeAnimation = false;
      this.welcomeIsActive = false;
      this.frontpageVisible = true;

      setTimeout(() => {
        const payload = { [this.establishmentHash]: false };

        this.showServiceDetail = false;
        this.$store.dispatch("setAppWelcomeShow", payload);
        this.$store.dispatch("setWelcomeShowActive", payload);
        this.$store.dispatch("setStatuswelcomeShowVisible", payload);

        this.serviceDetailData = false;
      }, 700);
    },

    async getWelcomeServices() {
      if (this.$store.state.welcomeShow[this.establishmentHash] === false || !this.data.welcomeMessageId) return;

      try {
        const response = await this.getService({
          serviceId: this.data.welcomeMessageId,
          serviceType: "welcome_message"
        });

        if (!response) throw new Error();

        const params = this.$router.currentRoute.value.query;
        let deeplinkDelay = 0;

        if (params?.ref && params.ref.indexOf("_DL") !== -1 && params.ref.split("_")[1]?.indexOf("DL") === -1) {
          this.frontpageVisible = true;
          deeplinkDelay = 4500;
        }

        setTimeout(() => {
          if (deeplinkDelay != 0) {
            this.frontpageVisible = false;
          }
          this.serviceDetailData = response;
          this.showServiceDetail = true;

          if (this.welcomeIsActive) {
            this.showWelcomeAnimation = true;
            this.scrollableBoxes = false;
            return;
          }

          this.$store.commit(types.WELCOME_SHOW_ACTIVE, true);
          setTimeout(() => {
            this.showWelcomeAnimation = true;
            this.$store.dispatch("setStatuswelcomeShowVisible", { [this.establishmentHash]: true });
            this.welcomeIsActive = true;
            setTimeout(() => {
              this.scrollableBoxes = false;
            }, 1000);
          }, 1000);
        }, deeplinkDelay);
      } catch (error) {
        console.error("Error getting serviceData:", error);
      }
    },

    checkAndShowPopupNotifications() {
      const isNotMobile = this.isDesktop || this.isLobby || this.isTV;
      const intervalSeconds = isNotMobile ? 7200 : 1200; //7200; //2 hours
      const checkedTs = checkHours(this.$store.state.notificationsTimestampPopup, intervalSeconds); //seconds

      if (checkedTs) {
        // within an interval of 2 hours, we show the popup about 5 times. After that we will show a native push popup as last chance. The fist time in frontpage will not be shown
        const times = this.$store.state.notificationsTimesShowed + 1;
        this.$store.commit(types.NOTIFICATIONS_TIMES_SHOWED, times);

        if (times > 1 && times < 7) {
          this.$store.commit(types.NOTIFICATIONS_TIMESTAMP_POPUP, Math.round(Date.now() / 1000));
          this.showFirstNotificationPopup = true;
        } else if (times > 1) {
          this.askNotificationPermission();
        }
      }
    },
    getBackgroundImage: getImageUrl,
    exitCongress() {
      this.confirmExitCongress = false;
    },

    showDigitalKey() {
      if (this.haveModuleDigitalKey()) {
        if (!this.isLogged()) {
          this.isDigitalKeyFunnelActive = true;
          this.openForm();
        } else {
          if (
            this.$store.state.tokenUserArray != undefined &&
            this.$store.state.tokenUserArray[this.$store.state.establishment.id].length > 0
          ) {
            showDigitalKeysFunnel(
              this.$router,
              this.$store.state.user.establishments[this.$store.state.establishment.id],
              this.$store.state.establishment.id,
              this.$store,
              false
            );
          } else {
            this.errorPopupMessage = this.getString("KEY_NO_TOKEN");
            this.showPopupError = true;
            this.showLoader = false;
          }
        }
      } else {
        this.errorPopupMessage = this.getString("DK_NO_SDK_NATIVE");
        this.showPopupError = true;
        this.showLoader = false;
      }
    },
    scrollProactive(hasForm) {
      if (hasForm) {
        EventBus.$emit("displayProactiveChange", { result: true });
      } else {
        EventBus.$emit("displayProactiveChange", { result: true });
        if (this.timer !== null) {
          clearTimeout(this.timer);
        }
        const self = this;
        this.timer = setTimeout(() => {
          EventBus.$emit("displayProactiveChange", { result: false });
        }, 300);
      }
    },
    handleScroll(value) {
      this.scrollTop = value;
    },
    welcomeMessage: function () {
      return this.getString("WELCOME_PWA_WIDGET").replace("%1", this.user && this.user.name ? this.user.name : "");
    },
    startEstablishmentData() {
      const self = this;

      if (!this.data && !this.frontpageData) {
        this.getFrontpage().then(response => {
          document.getElementsByTagName("main")[0].style.backgroundColor = "white";
          this.bubblesNumber = response.level1Nodes && response.level1Nodes.length ? response.level1Nodes.length : 0;
          this.$store.commit("frontpage/" + types.IS_DEEP_LINK, false);
          this.showChatWidget = response.widgetActive || response.botActive || response.smartSearchActive;

          if (response && response.mainColor) {
            this.$store.commit(types.UPDATE_MAIN_COLOR, response.mainColor);
          } else {
            this.$store.commit(types.UPDATE_MAIN_COLOR, "ffffff");
          }

          if (response.navBarTransparent) {
            this.$store.commit(types.FRONTPAGE_ICON, response.navBarTransparent);
          } else {
            this.$store.commit(types.FRONTPAGE_ICON, 0);
          }
          document.getElementsByTagName("html")[0].style.setProperty("--main-color", this.$store.state.mainColor);
          document
            .getElementsByTagName("html")[0]
            .style.setProperty("--cc-btn-primary-bg", this.$store.state.mainColor);
          if (response.textColor) {
            document.getElementsByTagName("html")[0].style.setProperty("--text-color", response.textColor);
          } else {
            document.getElementsByTagName("html")[0].style.setProperty("--text-color", "#34323D");
          }

          this.focusFirstElement();
        });
      } else {
        this.focusFirstElement();
      }

      this.loadingEstablishment = true;
      this.getEstablishment()
        .then(() => {
          this.loadingEstablishment = false;
          LogEstablishment(this.establishment);
          logChain(this.establishment);

          this.getStrings();

          if (this.screenNotLogged) {
            logScreen("/", "frontpage", "home");
          }
          if (this.establishment.requestChat && this.establishment.requestChat.allowed === true) {
            const event = new Event("enquiriesEnabled");
            dispatchEvent(event);
          }
          if (this.establishment.surveysVersion) {
            const event = new CustomEvent("updateSurveysVersion", { detail: this.establishment.surveysVersion });
            dispatchEvent(event);
          }
          if (this.establishment && this.establishment.loginType && this.establishment.loginType === 11) {
            this.hideLoginButton = true;
            this.$store.commit(types.ADD_TO_HOME, false);
          } else {
            this.showAddToHome();
          }
        })
        .catch(error => (this.loadingEstablishment = false));

      let messages = [];
      if (this.chatMessages && this.chatMessages.length > 0) {
        messages = this.chatMessages.filter(function (message) {
          return message.establishmentId === self.establishment.id;
        });
      } else {
        this.storeChatMessages([]);
      }

      if (!messages || messages.length === 0) {
        setTimeout(() => (this.showWelcomeMessage = true), 500);
      }
    },
    startUserDataWithDelay: function (delaySeconds) {
      const self = this;
      setTimeout(function () {
        self.startUserData();
      }, delaySeconds * 1000);
    },
    startUserData: function () {
      if (this.isLobby) {
        return;
      }

      this.getActivity().catch(e => {});

      this.getUserInbox()
        .then(response => {
          this.updateNotifications(response);
          this.showScheduledAlerts();

          // Delete when there are no more establishments in v1 of surveys
          if (
            this.establishment &&
            this.establishment.surveysVersion === 1 &&
            response.specialSurveys &&
            response.specialSurveys.length > 0
          ) {
            const specialSurvey = response.specialSurveys[0];
            specialSurvey.itemId = specialSurvey.id;

            const userIsNotPreClient =
              getUserStatus(this.user.establishments[this.user.establishmentId]) !== "PRE_CLIENT";

            if (specialSurvey.itemId && specialSurvey.itemId > 0 && userIsNotPreClient) {
              this.showSurveys([specialSurvey]);
            }
          }

          EventBus.$emit("refreshAllSurveyData");
        })
        .catch(error => {
          console.error("Error userInbox", error);
        });
    },

    openService(service) {
      let locale = "en";
      if (this.user.locale) {
        locale = this.user.locale;
      }
      const path = "/webview/";
      const params = {
        userkey: this.user.userKey,
        devicekey: this.user.deviceKey,
        serviceData: service,
        establishment: this.establishment.id,
        locale: locale,
        itemType: service.itemType ? service.itemType : 3,
        serviceType: service.typeString,
        id: service.id,
        imageId: service.photographs && service.photographs.length > 0 ? service.photographs[0] : null,
        psId: 0
      };

      this.$router.push({
        path: path,
        params: {
          itemType: params.itemType,
          serviceType: params.serviceType,
          id: params.id,
          imageId: params.imageId
        },
        name: "ServiceDetail",
        state: {
          params: cloneDeep(params)
        },
        query: { id: this.establishmentHash }
      });
    },
    mapLoginPmsResponse: function (obj) {
      const fieldsArray = [];
      for (var id in obj) {
        if (obj[id].hidden === true) {
          //we ignore hidden fields
        } else {
          let defaultValue = obj[id].default;
          if (!defaultValue) {
            defaultValue = "";
          }
          const field = {
            key: obj[id].key,
            required: obj[id].required,
            type: obj[id].type,
            ref: id,
            value: defaultValue,
            hidden: obj[id].hidden
          };
          fieldsArray.push(field);
        }
      }
      return fieldsArray;
    },
    showProfilePopup() {
      this.profilePopupVisible = !this.profilePopupVisible;
    },
    openForm: function () {
      if (this.isLobby) {
        this.lobbyQrPopupVisible = true;
        return;
      }

      if (this.showSurveyButton) {
        EventBus.$emit("displayProactiveChange", { result: true });
      }
      EventBus.$emit("visibleComponentFrontpage", true);

      if (this.isLogged()) {
        if (this.isDesktop) {
          this.hasPanel = !this.hasPanel;
          this.hasInbox = false;
        } else {
          this.$router.push("/guest-panel");
        }
        logVirtualPage("/vp/profile", "frontpage", "profile");
      } else {
        this.scrollableBoxes = true;
        if (this.hasForm) {
          logBackButton("/vp/login/home", "frontpage", "profile");
        } else {
          logVirtualPage("/vp/login/home", "frontpage", "profile");
        }

        if (this.hasNewPMS) {
          this.showLoader = true;
          this.getFieldsLoginPms()
            .then(response => {
              this.showLoader = false;
              const responseMapped = this.mapLoginPmsResponse(response);

              if (responseMapped) {
                this.logingFields = responseMapped;
                if (this.futureBookmarksPresent || this.hasVisitorBookings) {
                  const params = {
                    establishment: this.establishment.id,
                    logingFields: this.logingFields
                  };
                  if (this.isDesktop) {
                    this.hasPanel = !this.hasPanel;
                    this.hasInbox = false;
                  } else {
                    this.$router.push({
                      path: "/guest-panel",
                      state: { params: cloneDeep(params) },
                      name: "GuestPanel"
                    });
                  }
                  logVirtualPage("/vp/profile", "frontpage", "profile");
                } else {
                  this.hasForm = !this.hasForm;
                }
              } else {
                this.showLoader = false;
                this.errorMessage = this.getString("ERROR_PLEASE_TRY_AGAIN");
                this.showError = true;
                //modal.toast({message:  this.getString("ERROR_PLEASE_TRY_AGAIN"), duration: 2})
              }
            })
            .catch(error => {
              this.showLoader = false;
              this.errorMessage = this.getString(error);
              this.showError = true;
            });
        } else {
          this.hasForm = !this.hasForm;
        }
      }
    },
    checkFrontPushPermission() {
      return (this.hasPushPermission = checkPushPermission());
    },
    showFrontPushPermission() {
      return showPushPermission();
    },
    openAlertsAndCheck: function () {
      const showPermission = this.showFrontPushPermission(); // better in inbox component, checked inside
      this.openAlerts(showPermission);
    },
    openAlerts: function (showPermission) {
      if (this.isDesktop) {
        this.hasInbox = !this.hasInbox;
        this.hasPanel = false;
        const alerts = this.userInbox ? this.userInbox.alerts : null;
        this.scrollableBoxes = false;
        this.$refs["inbox"].showAlertDetail = false;

        if (alerts && Object.values(alerts) && Object.values(alerts).length) {
          const filterNow = Object.values(alerts).filter(alert => {
            return alert.trigger === "now";
          });
          if (filterNow.length > 0 && this.$refs["inbox"]) {
            this.$refs["inbox"].refreshInbox();

            this.hideHeader = true;
            logVirtualPage("/vp/alert", "frontpage", "notifications");
          } else {
            logVirtualPage("/vp/alert-bottom", "frontpage", "notifications");
          }
        } else {
          logVirtualPage("/vp/alert-bottom", "frontpage", "notifications");
        }
      } else {
        logVirtualPage("/vp/alert", "frontpage", "notifications");

        if (showPermission) {
          const params = {
            showPermission: true
          };
          this.$router.push({
            path: "/inbox/",
            state: { params: cloneDeep(params) },
            name: "Inbox",
            query: { id: this.establishmentHash }
          });
        } else {
          this.$router.push(`/inbox?id=${this.establishmentHash}`);
        }
      }
    },
    toChatScreen: function (value) {
      this.$store.commit(types.SHOW_DESKTOP_CHAT_SCREEN, value);
    },
    closeNotification: function (notification) {
      this.hideNotifications = true;
      this.$store.commit(types.DISMISS_NOTIFICATION, notification.data.id);
    },
    openMultipleNotifications: function () {
      if (this.isDesktop) {
        this.hasInbox = true;
        this.hasPanel = false;
        this.hideHeader = true;
        this.toChatScreen(false);
      } else {
        this.$router.push("/inbox");
      }
      this.$store.commit(types.DISMISS_NOTIFICATIONS);
      this.hideNotifications = true;
    },
    dismissAllNotifications: function () {
      this.$store.commit(types.DISMISS_NOTIFICATIONS);
      this.hideNotifications = true;
    },
    userBecameGuest: async function () {
      this.hasForm = false;

      if (this.$refs["guest-panel"]) {
        this.$refs["guest-panel"].$forceUpdate();
      }
      if (this.isDigitalKeyFunnelActive) {
        this.isDigitalKeyFunnelActive = false;
        this.showDigitalKey();
      }

      await this.getPromotions();
    },
    beforeEnterPanelGuest: function () {
      this.getActivity().catch(e => {
        console.error("Get Activity error", e);
      });
    },
    updateUser: function () {
      if (
        this.$store.state.user.establishments &&
        this.$store.state.user.establishments[this.$store.state.establishment.id] &&
        (this.$store.state.user.establishments[this.$store.state.establishment.id].room ||
          this.$store.state.user.establishments[this.$store.state.establishment.id].cliId)
      ) {
        const data = {};
        if (this.$store.state.user.establishments[this.$store.state.establishment.id].name) {
          data.name = this.$store.state.user.establishments[this.$store.state.establishment.id].name;
        }
        if (this.$store.state.user.establishments[this.$store.state.establishment.id].room) {
          data.room = this.$store.state.user.establishments[this.$store.state.establishment.id].room;
        }
        if (this.$store.state.user.establishments[this.$store.state.establishment.id].checkIn) {
          data.checkIn = this.$store.state.user.establishments[this.$store.state.establishment.id].checkIn;
        }
        if (this.$store.state.user.establishments[this.$store.state.establishment.id].checkOut) {
          data.checkOut = this.$store.state.user.establishments[this.$store.state.establishment.id].checkOut;
        }
        if (this.$store.state.user.establishments[this.$store.state.establishment.id].cliId) {
          data.cliId = this.$store.state.user.establishments[this.$store.state.establishment.id].cliId;
        }
        this.$store.commit(types.UPDATE_USER, data);
      }
      logUserID(this.user.id);
    },

    updateNotifications(response) {
      const notificationsArray = Object.assign(this.notifications, []);
      const promises = [];

      if (response.bookings && response.bookings.length) {
        response.bookings.forEach(booking => {
          if (isChangeInBookingNotifiable(booking) && !checkMessageIsClient(booking)) {
            let descriptionStatus = "";
            let comment = "";
            if (booking.hasOwnProperty("serviceComment") && booking.serviceComment.length) {
              if (checkIsMessage(booking)) {
                comment = booking.serviceComment;
              }
            }

            if (booking.hasOwnProperty("bookingDate")) {
              descriptionStatus = capitalized(moment(booking.bookingDate).locale(this.locale).format("dddd DD"));
            }

            if (booking.hasOwnProperty("bookingHour")) {
              descriptionStatus =
                descriptionStatus + ", " + this.getString("AT") + " " + booking.bookingHour.substring(0, 5);
            }
            if (booking.totalPax) {
              descriptionStatus = descriptionStatus + " - " + booking.totalPax + this.getString("PAX");
            }

            promises.push(
              this.getService({
                serviceType: "",
                serviceId: booking.service
              }).then(service => {
                let spaceOrderStatus = undefined;
                if (
                  booking &&
                  booking.customData &&
                  booking.customData.bookingSpace &&
                  booking.customData.bookingSpace.hasOwnProperty("orderStatus")
                ) {
                  spaceOrderStatus = booking.customData.bookingSpace.orderStatus;
                }
                notificationsArray.push({
                  type: "booking",
                  status: booking.status,
                  comment: comment,
                  serviceType: service.type,
                  serviceId: booking.service,
                  bookingEnabled: booking?.customData?.bookingSpace?.bookingEnabled,
                  spaceOrderStatus: spaceOrderStatus,
                  data: {
                    id: booking.id,
                    title: this.translate(service.translatableName, this.user),
                    description: descriptionStatus,
                    image: service.photographs && service.photographs.length ? service.photographs[0] : null
                  }
                });
              })
            );
          }
        });
      }
      //not used anymore?
      if (response.orders && Object.values(response.orders).length) {
        Object.values(response.orders).forEach(booking => {
          let showPush = true;
          if (booking.status == 0 || booking.status == 9) {
            if (booking.hasOwnProperty("serviceComment") && booking.serviceComment.length > 0) {
              showPush = true;
            } else {
              showPush = false;
            }
          }
          if (showPush) {
            let descriptionStatus = "";
            let comment = "";
            if (booking.serviceComment && booking.serviceComment.length) {
              comment = booking.serviceComment;
            }
            if (booking.hasOwnProperty("bookingDate")) {
              descriptionStatus = capitalized(moment(booking.bookingDate).locale(this.locale).format("dddd DD"));
            }
            if (booking.hasOwnProperty("bookingHour")) {
              descriptionStatus =
                descriptionStatus + ", " + this.getString("AT") + " " + booking.bookingHour.substring(0, 5);
            }
            if (booking.totalPax) {
              descriptionStatus = descriptionStatus + " - " + booking.totalPax + this.getString("PAX");
            }

            promises.push(
              this.getService({
                serviceType: "",
                serviceId: booking.service
              }).then(service => {
                notificationsArray.push({
                  type: "order",
                  status: booking.status,
                  comment: comment,
                  serviceType: service.type,
                  data: {
                    id: booking.id,
                    title: this.getString("ROOM_SERVICE"),
                    description: descriptionStatus,
                    image: service.photographs && service.photographs.length ? service.photographs[0] : null
                  }
                });
              })
            );
          }
        });
      }
      if (response.requests && response.requests.length) {
        response.requests.forEach(booking => {
          if (isChangeInRequestNotifiable(booking) && !checkMessageIsClient(booking)) {
            let descriptionStatus = "";
            let comment = "";
            if (booking.hasOwnProperty("statusComment") && booking.statusComment.length > 0) {
              if (checkIsMessage(booking)) {
                comment = booking.statusComment;
              }
            }

            promises.push(
              this.getService({
                serviceType: "",
                serviceId: booking.service
              }).then(service => {
                if (booking.hasOwnProperty("creationDatetime")) {
                  descriptionStatus = capitalized(
                    moment(booking.creationDatetime.substring(0, 10)).locale(this.locale).format("dddd DD")
                  );
                }
                if (booking.customData.hasOwnProperty("productNames")) {
                  descriptionStatus =
                    descriptionStatus + " - " + this.translate(booking.customData.productNames, this.user);
                }

                let type = "lead";
                let title = this.translate(service.translatableName, this.user);
                if (booking.cmsModule === 97) {
                  type = "request_chat";
                  title = "Concierge Chat";
                  descriptionStatus = this.establishment.name;
                  if (checkIsObject(booking)) {
                    comment = this.getString("STAFF_INFORMATION_RECEIVED");
                  }
                }
                notificationsArray.push({
                  type: type,
                  status: booking.status,
                  comment: comment,
                  serviceId: service.id,
                  serviceType: service.type,
                  data: {
                    id: booking.id,
                    title: title,
                    description: descriptionStatus,
                    image: service.photographs && service.photographs.length ? service.photographs[0] : null
                  }
                });
              })
            );
          }
        });
      }

      if (response.alerts && Object.values(response.alerts).length) {
        Object.values(response.alerts).forEach(alert => {
          if (alert.trigger !== "now") {
            return;
          }

          const notification = {
            type: alert.alertType === "promotion" ? alert.alertType : "alert",
            data: {
              id: alert.id,
              title: this.translate(alert.alertTitle, this.user),
              description: this.translate(alert.alertDescription, this.user)
            }
          };

          if (alert.alertType === "promotion") {
            notification.data.data = alert;
          }

          notificationsArray.push(notification);
        });
      }

      // We need to read alerts & surveys from userInbox to get checkout surveys
      if (this.userInbox.alerts && Object.values(this.userInbox.alerts).length) {
        Object.values(this.userInbox.alerts).forEach(alert => {
          if (alert.trigger === "checkout") {
            const timezone = this.establishment.timezone;
            let mom = moment();
            if (alert.configuration.triggerDate) {
              mom = moment(alert.configuration.triggerDate + " " + alert.configuration.triggerTime).tz(timezone);
            } else {
              const time = moment(alert.configuration.triggerTime, "HH:mm").tz(timezone);
              mom.set({
                hour: time.get("hour"),
                minute: time.get("minute"),
                second: time.get("second")
              });
            }
            const now = moment();
            const userCheckout = this.user.checkOut;

            if (
              this.userInbox.surveys &&
              alert.itemId &&
              alert.configuration &&
              alert.configuration.itemTypeAssociated === 15 &&
              alert.configuration.triggerTime
            ) {
              if (
                now.isSameOrAfter(mom) &&
                userCheckout &&
                now.format("YYYY-MM-DD") === userCheckout &&
                this.userInbox.surveys[alert.itemId]
              ) {
                this.showSurveys([this.userInbox.surveys[alert.itemId]]);
              }
            } else {
              if (alert.itemId == undefined) {
                if (now.isSameOrAfter(mom) && userCheckout && now.format("YYYY-MM-DD") === userCheckout) {
                  if (alert.show == undefined || alert.show != 1) {
                    alert.show = 1;
                    notificationsArray.push({
                      type: "alert",
                      data: {
                        id: alert.id,
                        title: this.translate(alert.alertTitle, this.user),
                        description: this.translate(alert.alertDescription, this.user)
                      }
                    });
                  }
                }
              }
            }
          }
        });
        if (this.$refs["inbox"]) {
          this.$refs["inbox"].refreshInbox();
        }
      }

      this.hideNotifications = false;

      Promise.all(promises).then(() => {
        this.$store.commit(types.CHANGE_NOTIFICATIONS, notificationsArray);
        this.logGTMNotificationsIfPresent();
      });
    },
    logGTMNotificationsIfPresent() {
      if (this.notifications && this.notifications.length > 0) {
        logVirtualPage("/vp/alert-banner", "frontpage", "notifications");
      }
    },
    showScheduledAlerts() {
      const forceSurvey = false; //test
      if (!this.userInbox) {
        return;
      }
      const alertsTimetable = this.userInbox.alertsTimetable;
      if (alertsTimetable && Object.keys(alertsTimetable).length > 0) {
        let alertsToShow = [];
        const timezone = this.establishment.timezone;
        Object.keys(alertsTimetable).forEach(function (dateAndTime) {
          const mom = moment(dateAndTime).tz(timezone);
          const now = moment();
          if (forceSurvey) {
            alertsToShow = alertsToShow.concat(alertsTimetable[dateAndTime]);
          } else {
            if (now.isSameOrAfter(mom)) {
              // Pending logic to check if past
              alertsToShow = alertsToShow.concat(alertsTimetable[dateAndTime]);
            }
          }
        });
        if (alertsToShow.length > 0) {
          this.showScheduledAlertArray(alertsToShow);
        } else {
          this.surveyData = false;
        }
      } else {
        this.surveyData = false;
      }
    },
    showScheduledAlertArray(idAlertsArray) {
      const surveyAlertsArray = [];
      const self = this;
      idAlertsArray.forEach(function (idAlert) {
        const alertData = self.userInbox.alerts[idAlert + ""];
        if (alertData && alertData.itemType === 8) {
          // Survey Alert
          surveyAlertsArray.push(alertData);
        }
      });
      this.showSurveys(surveyAlertsArray);
    },
    showSurveys(surveyAlertsArray) {
      if (
        this.establishment.surveysVersion !== 1 ||
        this.pendingTermsConditions[this.establishment.id] ||
        surveyAlertsArray.length === 0
      ) {
        return;
      }

      const alertData = surveyAlertsArray.shift();
      const idSurvey = alertData.itemType && alertData.itemType === 15 ? alertData.id : alertData.itemId;

      this.getSurvey(idSurvey).then(response => {
        if (!response || !response.itemBames) {
          return this.showSurveys(surveyAlertsArray);
        }

        const surveyNames = response.itemNames;
        const firstKey = Object.keys(surveyNames)[0];
        let surveyKey = response.itemType + "-" + firstKey + "-" + response.id;

        if (response.itemType === 7) {
          response.itemType = 3;
          if (surveyNames && surveyNames.length && surveyNames.length === 1 && firstKey) {
            response.itemId = parseInt(firstKey);
          } else {
            response.itemId = this.getSurveyItemId(response.id, parseInt(firstKey), response.itemId);
          }
          surveyKey = response.itemType + "-" + response.itemId + "-" + response.id;
        }

        const surveyAlreadyAnswered = (this.userInbox?.surveysAnswers ?? {})[surveyKey];
        const surveyHidden = this.hiddenSurveys[surveyKey];

        if (surveyAlreadyAnswered || !firstKey || surveyHidden) {
          if (
            surveyHidden &&
            !surveyAlreadyAnswered &&
            this.welcomeSurveyResponseDate !== moment().format("YYYY-MM-DD")
          ) {
            // Welcome survey filled on previous days, we need to show it again today
            this.surveyData = response;
          } else {
            this.showSurveys(surveyAlertsArray);
          }
        } else {
          this.surveyData = response;
        }
      });
    },
    hideThisSurvey(surveyData) {
      const surveyNames = surveyData.itemNames;
      if (surveyNames) {
        const firstKey = Object.keys(surveyNames)[0];
        const surveyKey = surveyData.itemType + "-" + firstKey + "-" + surveyData.id;
        this.hideSurvey(surveyKey);
      }

      if (surveyData.itemType === 15) {
        // Welcome survey
        this.saveWelcomeSurveyResponseDate(moment().format("YYYY-MM-DD"));
      }
    },
    showAddToHome() {
      if (!this.isAndroid() && this.isIos() && !this.addToHomeAlreadyShown && !window.navigator.standalone) {
        setTimeout(() => {
          this.showAddToHomePopup = true;
        }, 2000);
        this.$store.commit(types.ADD_TO_HOME, true);
      }
    },
    extractMessageUtmReferral(ref) {
      const regexResult = ref && /UTM_([^_]+)/.exec(ref);
      if (regexResult && regexResult.length > 1) {
        return regexResult[1];
      } else {
        return false;
      }
    },
    createPreGuestWithPS(ref, createVisitorData) {
      const data = {};
      return new Promise((resolve, reject) => {
        if (createVisitorData?.utm) {
          data.utm = createVisitorData.utm;
        }
        data.preStay = this.$store.state.preStayCode;

        this.startEstablishmentData();
        if (data.preStay) {
          this.createGuestReferralWithPS(data)
            .then(response => {
              const establishmentsInfo =
                response.establishments && response.establishments[this.$store.state.establishment.id]
                  ? response.establishments[this.$store.state.establishment.id]
                  : false;

              if (
                establishmentsInfo &&
                (establishmentsInfo.userType === "CLIENT" || establishmentsInfo.userType === "PRECLIENT")
              ) {
                this.$store.commit(types.UPDATE_USER, {
                  name: establishmentsInfo.name,
                  room: establishmentsInfo.room,
                  checkIn: establishmentsInfo.checkIn,
                  checkOut: establishmentsInfo.checkOut
                });

                if (response.device && response.device.uid) {
                  this.$store.commit(types.CHANGE_DEVICE_UID, response.device.uid);
                }
                if (response.pushToken) {
                  this.$store.commit(types.SET_PUSH_TOKEN, response.pushToken);
                }

                this.resetUrlWithParams();
              }

              resolve(response);
            })
            .catch(error => {
              console.error(error);
              reject(error);
            })
            .finally(() => {
              this.$store.commit(types.PRESTAY_CODE, undefined);
            });
        } else {
          this.$store.commit(types.PRESTAY_CODE, undefined);
          reject();
        }
      });
    },

    resetUrlWithParams() {
      const desktopURL = this.isDesktop ? "/desktop" : "";
      let urlReplace = desktopURL + "/?id=" + this.establishmentHash;

      const urlParams = new URLSearchParams();
      const paramsArray = ["utm_source", "utm_medium", "utm_campaign"];
      paramsArray?.forEach(param => {
        if (this.$store.state[param]) {
          urlParams.append(param, this.$store.state[param]);
        }
      });

      const separator = urlParams?.size > 0 ? "&" : "";
      urlReplace = `${urlReplace}${separator}${urlParams?.toString()}`;
      this.$router.replace(urlReplace);
    },
    createPreGuestWithReferral(ref, fromPreStayRequest) {
      const referralComponents = ref.split("_");
      const data = {};
      return new Promise((resolve, reject) => {
        if (referralComponents.length > 1) {
          const idPreStay = referralComponents[1];
          if (idPreStay) {
            data.linkId = idPreStay;
          }
          if (ref.indexOf("UTM") !== -1) {
            data.utm = this.extractMessageUtmReferral(ref);
          }

          if (!fromPreStayRequest) {
            this.startEstablishmentData();
          }
          this.createGuestReferral(data)
            .then(response => {
              if (
                response.establishments &&
                response.establishments[this.$store.state.establishment.id] &&
                (response.establishments[this.$store.state.establishment.id].userType === "CLIENT" ||
                  response.establishments[this.$store.state.establishment.id].userType === "PRECLIENT")
              ) {
                this.$store.commit(types.UPDATE_USER, {
                  name: response.establishments[this.$store.state.establishment.id].name,
                  room: response.establishments[this.$store.state.establishment.id].room,
                  checkIn: response.establishments[this.$store.state.establishment.id].checkIn,
                  checkOut: response.establishments[this.$store.state.establishment.id].checkOut
                });

                this.resetUrlWithParams();
              }
              resolve(response);
            })
            .catch(error => {
              console.error(error);
              reject(error);
            });
        } else {
          resolve();
        }
      });
    },
    deepLink(ref) {
      this.startEstablishmentData();
      const idLink = ref.split("_")[1];
      const data = this.user;
      data.idLink = idLink;
      if (idLink && idLink.indexOf("DL") === -1) {
        this.getDeepLinkData(data)
          .then(response => {
            const action = response.action;
            const type = response.type;

            if (action === "syncestablishment") {
              setTimeout(() => {
                this.modifyRefUrl();
              }, 50);
            } else if (action === "gotosection" && type === "checkIn") {
              const userData = this.user;
              userData.establishmentId = this.establishment.id;
              userData.locale = this.locale;
              userData.id = userData.uid;
              let url = getItemUrl(1, 0, 0, userData, 72);
              url += "?email=" + response.email + "&locator=" + response.locator;
              this.$router.push({
                path: url
              });
            } else if (action === "gotosection") {
              this.$store.commit(types.DEEPLINK_GOTOSECTION, true);

              const pdfItem = response.pdfId;
              const menuId = response.menuId;
              const catalogueId = response.catalogueId;
              const siteId = response.siteId;

              const pathLink = getItemUrl(
                response.itemType,
                response.itemId,
                response.serviceType,
                {},
                response.cmsModule,
                response.serviceId,
                this.isLobby,
                this.isDesktop,
                this.isTV
              );

              setTimeout(() => {
                this.modifyRefUrl();
                setTimeout(() => {
                  if (pdfItem || menuId || catalogueId || siteId) {
                    if (pdfItem) {
                      this.$router.push({
                        params: { pdfId: pdfItem },
                        path: pathLink
                      });
                    } else if (catalogueId) {
                      this.$router.push({
                        params: { catalogueId: catalogueId },
                        path: pathLink
                      });
                    } else if (menuId) {
                      this.$router.push({
                        params: { menuId: menuId },
                        path: pathLink
                      });
                    } else if (siteId) {
                      this.$router.push({
                        params: { siteId: siteId },
                        query: { siteId: siteId },
                        path: pathLink
                      });
                    }
                    this.getService({
                      serviceType: response.serviceType,
                      serviceId: response.serviceId
                    }).then(service => {
                      if (catalogueId) {
                        this.catalogueService = service;
                        this.getServiceCatalogues({
                          serviceId: response.serviceId,
                          serviceType: service.typeString
                        }).then(catalogues => {
                          if (catalogues) {
                            if (service.typeString != "roomservicesV2") {
                              const selectedCatalogue = catalogues.find(element => element.id === catalogueId);
                              if (selectedCatalogue) {
                                if (!this.isSpacesOrderCheck(service)) {
                                  this.openCatalogueURL(selectedCatalogue);
                                } else {
                                  this.$router.replace({
                                    params: { catalogueId: catalogueId },
                                    query: { spaceCatalogueId: catalogueId }
                                  });
                                }
                              }
                            }
                          }
                        });
                      } else if (service && service.menusOnPdf && service.menusOnPdf.length) {
                        let menuById = undefined;
                        service.menusOnPdf.forEach(menu => {
                          let isThisMenu = undefined;
                          if (menuId) {
                            isThisMenu = this.checkPdfMenuByMenuId(menuId, menu);
                          } else if (pdfItem) {
                            isThisMenu = this.checkPdfMenu(pdfItem, menu);
                          }
                          if (isThisMenu) {
                            menuById = menu;
                          }
                        });
                        if (menuById) {
                          this.openPdfMenuURL(menuById, true);
                        }
                      }
                    });
                  } else {
                    if (
                      //weather, chat, search and enquiries
                      response.cmsModule &&
                      (response.cmsModule == 95 ||
                        response.cmsModule == 70 ||
                        response.cmsModule == 63 ||
                        response.cmsModule == 97) &&
                      (this.isDesktop || this.isLobby || this.isTV)
                    ) {
                      EventBus.$emit("desktopDeeplinksListener", response);
                    } else {
                      if (response.cmsModule == 97) {
                        if (response.itemId == 97 && response.serviceType == 47 && !response.serviceId) {
                          EventBus.$emit("enquiriesButtonAction", {
                            itemType: 15,
                            itemId: this.establishment.id,
                            title: this.getString("REQUEST_CHAT_TITLE")
                          });
                        } else {
                          EventBus.$emit("enquiriesButtonAction", {
                            itemId: response.itemId,
                            itemType: response.itemType,
                            serviceType: response.serviceType
                          });
                        }
                        return;
                      } else {
                        this.$router.push({
                          path: pathLink,
                          query: { id: this.establishmentHash }
                        });
                      }
                    }
                  }
                }, 70);
              }, 70);
            } else if (action === "autologin") {
              this.createGuestAutologin(response).then(responseAutologin => {
                this.startUserData();
                if (
                  !this.pendingTermsConditions ||
                  this.pendingTermsConditions[this.$store.state.establishment.id] !== false
                ) {
                  this.showWelcome = true;
                  this.emitShowWelcome();
                  this.togglePendingTerms(true);
                }
              });
            }
          })
          .catch(err => {
            console.error("deeplink error", err);
          });
      }
    },
    openCatalogueURL(catalogue) {
      if (catalogue.type === "pdf" && catalogue.pdf) {
        this.openPdfMenuURL(catalogue.pdf, true);
      } else if (catalogue.type === "url" && catalogue.url) {
        this.openPdfMenuURL(catalogue.url, false);
      } else if (catalogue.type === "products") {
        this.toCatalogue(catalogue);
      }
    },
    toCatalogue(catalogue) {
      this.$router.push({
        name: "ServiceCatalogueWithId",
        params: {
          catalogueId: catalogue.id,
          serviceType: this.catalogueService.typeString,
          serviceId: catalogue.service
        },
        state: {
          params: cloneDeep({
            catalogue: catalogue,
            id: catalogue.service,
            serviceData: this.catalogueService,
            itemType: this.catalogueService.itemType ? this.catalogueService.itemType : 3
          })
        },
        query: { id: this.establishmentHash }
      });
    },
    //deleting ref from query
    modifyRefUrl() {
      const params = this.$router.currentRoute.value.query;
      const paramsFilter = {};

      Object.keys(params).forEach(key => {
        const value = params[key];
        if (key.indexOf("ref") === -1) {
          paramsFilter[key] = value;
        }
      });
      let paramsFilterUrl = "";
      let counter = 0;
      Object.keys(paramsFilter).forEach((key, index) => {
        const value = paramsFilter[key];
        if (key.indexOf("ref") === -1) {
          if (counter == 0) {
            paramsFilterUrl = paramsFilterUrl + "?" + key + "=" + value;
          } else {
            paramsFilterUrl = paramsFilterUrl + "&" + key + "=" + value;
          }

          counter++;
        }
      });

      if (paramsFilterUrl && paramsFilterUrl.length) {
        this.$router.replace(paramsFilterUrl);
      }
    },
    getString(key) {
      const user = cloneDeep(this.user);
      user.locale = this.getLocale();
      return getString(this.strings, key, user);
    },
    getLocale: function () {
      let locale = "en";
      if (this.user.locale) {
        locale = this.user.locale;
      }
      return locale;
    },
    togglePendingTerms(value) {
      this.setPendingTermsConditions({ establishment: this.$store.state.establishment.id, value: value });
    },
    getEventsToFrontpage() {
      this.modifyInitialIndex(0);
      this.hasNewEvent = false;

      if (this.congressEvents) {
        const eventsList = [];
        let indexNewEvent = 0;
        let counter = 0;

        Object.keys(this.congressEvents).some(key => {
          const event = this.congressEvents[key];
          if (event && !this.checkEventPast(event) && event.establishment === this.establishment.id) {
            eventsList.push(event);
            this.hasEvents = true;
            if (this.newEventId === event.id) {
              indexNewEvent = counter;
              this.hasNewEvent = true;
            }
          }
          counter++;
        });

        this.modifyInitialIndex(indexNewEvent);
        this.$store.commit(types.NEW_EVENT_ID, 0);
        this.actualEvents = eventsList;
      }
    },
    deleteIdCongress() {
      this.$store.commit(types.CONGRESS_ID_REDIRECT, "");
    },
    openInputCode(code) {
      const isPublic = false;
      if (code) {
        this.linkCongressByCode(isPublic, code);
      }
    },
    congressFromPopup: function (data) {
      if (data && data.code) {
        this.showPopupCode = false;
        //mainly public
        if (this.user && this.isLogged(this.user) && data.hasOwnProperty("codeIsCongressId") && data.codeIsCongressId) {
          this.linkCongressByCode(true, this.congressIdRedirect);
        } else if (this.user && this.isLogged(this.user)) {
          this.openInputCode(data.code);
        } else {
          if (data.loginType == 1) {
            this.askForLoginFields();
          } else {
            if (data.fullName !== "") {
              this.showCongressLoader = true;
              this.updateUserData(data.fullName)
                .then(() => {
                  this.openInputCode(data.code);
                })
                .catch(err => {
                  this.showCongressLoader = false;
                  this.manageCodeError(err);
                });
            } else {
              this.manageCodeError("EVENT_ERROR_FULLNAME");
            }
          }
        }
      } else {
        // this.showPopupCode = false;
        this.closeCongressPopup();
        this.manageCodeError("EVENT_ERROR_CODE");
      }
    },
    userNameUpdated(data) {
      this.showCongressPopup = false;
      if (this.user && this.isLogged(this.user)) {
        this.linkCongressByCode(true, this.congressIdRedirect);
      } else {
        if (data.loginType == 1) {
          this.askForLoginFields();
        } else {
          if (data.fullName) {
            this.showCongressLoader = true;
            this.updateUserData(data.fullName)
              .then(() => {
                this.linkCongressByCode(true, this.congressIdRedirect);
              })
              .catch(err => {
                this.closeCongressPopup();
                this.showCongressLoader = false;
                this.manageCodeError(err);
              });
          } else {
            this.manageCodeError("EVENT_ERROR_FULLNAME");
            this.closeCongressPopup();
          }
        }
      }
    },
    askForLoginFields() {
      this.openForm();
    },
    closeCongressPopup() {
      this.showCongressPopup = false;
      this.deleteIdCongress();
    },
    enterToEventById(id) {
      enterToEvent(id);
    },
    linkCongressByCode(isPublic, congressIdOrCode) {
      this.showCongressLoader = true;
      this.linkCongress({ codeOrId: congressIdOrCode, isPublic: isPublic })
        .then(response => {
          if (response && response.congress) {
            const idCongress = response.congress;

            this.getCongress(idCongress)
              .then(responseCongress => {
                this.$store.commit(types.NEW_CONGRESS, true);
                this.$store.commit(types.EVENTS, responseCongress);
                this.$store.commit(types.NEW_EVENT_ID, responseCongress.id);

                this.closeCongressPopup();
                this.showCongressLoader = false;
                this.enterToEventById(responseCongress.id);
              })
              .catch(error => {
                console.error("Error in linkCongressByCode: ", error);
                this.showCongressLoader = false;
                if (error.length > 2) {
                  this.manageCodeError(error);
                } else {
                  this.manageCodeError("ERROR_PLEASE_TRY_AGAIN");
                }
                this.closeCongressPopup();
              });
          } else {
            this.manageCodeError("ERROR_PLEASE_TRY_AGAIN");
            this.closeCongressPopup();
            this.showCongressLoader = false;
          }
        })
        .catch(error => {
          this.closeCongressPopup();
          this.manageCodeError(error);
          this.showCongressLoader = false;
        });
    },
    manageCodeError(errorStr) {
      if (errorStr) {
        if (checkStr(errorStr)) {
          this.showPopupError = true;
          this.errorPopupMessage = this.getString(errorStr);
        }
      }
    },
    checkCongressPopup() {
      this.showCongressPopup = false;

      if (this.congressIdRedirect) {
        let congressRepeated = false;
        if (this.actualEvents && this.actualEvents.length) {
          this.actualEvents.forEach(congress => {
            if (congress.id === this.congressIdRedirect) {
              this.deleteIdCongress();
              congressRepeated = true;
            }
          });
        }

        if (!congressRepeated) {
          this.getCongress(this.congressIdRedirect)
            .then(response => {
              if (response.translatableName) {
                const nameCongressRedirect = this.translate(response.translatableName);

                if ((!response.hasOwnProperty("congressCode") || !response.congressCode) && response.isPublic) {
                  this.congressRedirectIsPublic = true;
                } else {
                  this.congressRedirectIsPublic = false;
                }

                this.headerCongressRedirect = nameCongressRedirect;
                this.showCongressPopup = true;
                this.showCongressLoader = false;
              } else {
                this.showCongressLoader = false;
              }
            })
            .catch(error => {
              console.error("Error congress: ", error);
              this.showCongressLoader = false;
            });
        } else {
          this.showCongressLoader = false;
        }
      } else {
        this.showCongressLoader = false;
      }
    },
    modifyInitialIndex(index) {
      this.flickityOptions.initialIndex = index;
    },
    //do not show past events in frontpage
    checkEventPast(event) {
      if (event.endDate) {
        //we add one day to avoid problems with hours
        const endMoment = moment(moment(event.endDate).add(1, "days").format("YYYY-MM-DD"));
        const momentNow = moment();
        moment.locale(this.locale);
        if (endMoment >= momentNow) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
    focusFirstElement() {
      // Focus first bubble by default for TV
      setTimeout(() => {
        const firstSelectItem = navigationService.getFocusElementById("frontpage-bubble-item-0_0");
        firstSelectItem && firstSelectItem.focus();
      }, 1000);
    },
    showChat() {
      setTimeout(() => {
        if (this.data.smartSearchActive) {
          this.$router.push("/smart-search");
        } else {
          this.$router.push("/chat");
        }
      }, 200);
    },
    autoLoginWithReferralFailed(message) {
      this.showWelcome = false;
      this.showError = true;
      this.errorMessage = this.getString(message);
      this.togglePendingTerms(false);
      this.resetUrlWithParams();
    },
    askNotificationPermission() {
      setTimeout(() => {
        showFirstPushPermission(this.$store.state);
      }, 10);
    },

    checkIfUpsellingNode(node) {
      const isNotEmpty = !!node.length;
      const enabledOption = node.some(item => {
        return item.hasOwnProperty("enabled") ? item.enabled === true : true;
      });
      return isNotEmpty && enabledOption;
    },
    async getPromotions() {
      try {
        if (!this.$store.state.user.userKey) {
          return;
        }

        const promotions = await this.$store.dispatch("promotions/getAll");

        await this.parsePromosToNotifications(promotions);
        this.displayUpsellPopupData();
      } catch (e) {
        console.error(e);
      }
    }
  },
  async mounted() {
    if (!this.isLobby && this.data?.welcomeMessage) {
      this.getWelcomeServices();
    }

    await this.getPromotions();
  },

  components: {
    LoginForm,
    LoginPmsForm,
    Notification,
    Survey,
    SurveyButton,
    WelcomePopup,
    ServicePopup,
    ServiceDetail,
    UpsellPopup,
    ProfilePopup,
    LobbyQrPopup
  },
  watch: {
    starterPlan: {
      handler(value) {
        this.poweredBy = value;
      },
      immediate: true
    }
  },
  created() {
    EventBus.$emit("visibleComponentFrontpage", false);
    EventBus.$on("enableScroll", event => {
      this.scrollableBoxes = true;
    });
    EventBus.$on("disableScroll", event => {
      this.scrollableBoxes = false;
    });
    EventBus.$off("showSurveyProactive");
    EventBus.$on("showSurveyProactive", () => {
      this.surveyProactiveAvailable = true;
    });

    this.multipleNotifications = {
      type: "multiple",
      data: {
        id: "multiple",
        image: this.data.backgroundImage,
        title: this.getString("YOU_HAVE_MULTIPLE_NOTIFICATIONS")
      }
    };
    if (this.$store.state.welcomeShowActive[this.establishmentHash] && this.data.welcomeMessage) {
      let welcomeTime = 0;
      if (this.$store.state.deeplinkGoToSection) {
        welcomeTime = 2500;
        this.$store.commit(types.DEEPLINK_GOTOSECTION, false);
      }
      setTimeout(() => {
        if (welcomeTime != 0) {
          this.frontpageVisible = false;
          this.welcomeIsActive = true;
          setTimeout(() => {
            this.showWelcomeAnimation = true;
            this.scrollableBoxes = false;
          }, 1000);
        } else {
          setTimeout(() => {
            this.frontpageVisible = false;
            this.welcomeIsActive = true;
            this.showWelcomeAnimation = true;
            this.scrollableBoxes = false;
          }, 2000);
        }
      }, welcomeTime);
    } else {
      this.frontpageVisible = true;
      this.welcomeIsActive = false;
    }

    EventBus.$on("showEnquiries", event => {
      this.scrollableBoxes = !event.showList && !event.showChat;
    });
    EventBus.$on("showSearchFromChat", event => {
      this.showSmartSearch = true;
    });

    EventBus.$off("listenerCongressUrlParam");
    EventBus.$on("listenerCongressUrlParam", result => {
      const delaytime = 200;
      this.showCongressLoader = true;
      if (!this.strings || this.strings.length === 0) {
        setTimeout(() => {
          if (!this.strings || this.strings.length === 0) {
            this.getStrings()
              .then(() => {
                this.checkCongressPopup();
              })
              .catch(err => {
                this.checkCongressPopup();
              });
          } else {
            this.checkCongressPopup();
          }
        }, delaytime);
      } else {
        this.checkCongressPopup();
      }
    });

    EventBus.$emit("listenerCongressUrlParamReady");

    EventBus.$off("serviceWorkerStartSubscription");
    EventBus.$on("serviceWorkerStartSubscription", () => {
      logVirtualPage("/vp/alert-detail-pwa", "frontpage", "notifications");
    });
    EventBus.$off("serviceWorkerDidSubscribeUser");
    EventBus.$on("serviceWorkerDidSubscribeUser", result => {
      this.checkFrontPushPermission();
      logGTMEvent("/vp/alert-detail-pwa", "frontpage", "notifications", "pwa", "notification", "accepted");
    });
    EventBus.$off("serviceWorkerDidFailSubscribingUser");
    EventBus.$on("serviceWorkerDidFailSubscribingUser", () => {
      logGTMEvent("/vp/alert-detail-pwa", "frontpage", "notifications", "pwa", "notification", "declined");
    });

    EventBus.$off("serviceWorkerNotificationPopup");
    EventBus.$on("serviceWorkerNotificationPopup", result => {
      this.checkAndShowPopupNotifications();
    });
    EventBus.$off("checkAndShowPopupNotificationsEvent");
    EventBus.$on("checkAndShowPopupNotificationsEvent", result => {
      this.checkAndShowPopupNotifications();
    });
    EventBus.$off("subscriptionInactive");
    EventBus.$on("subscriptionInactive", () => {
      this.showSurvey = false;
      this.showWelcome = false;
    });
    EventBus.$off("showInbox");
    EventBus.$on("showInbox", () => {
      this.hasInbox = true;
      this.hasPanel = false;
      this.hideHeader = true;
      this.toChatScreen(false);
    });
    EventBus.$off("showGuestPanel");
    EventBus.$on("showGuestPanel", () => {
      this.toChatScreen(false);
      this.hasPanel = true;
      this.hasInbox = false;
    });

    logProductType(this.isLobby ? "lobby" : "pwa"); // It will be 'app' in Weex

    if (this.establishment && this.establishment.id && this.establishment.name) {
      LogEstablishment(this.establishment);
      logChain(this.establishment);
      logScreen("/", "frontpage", "home");
      this.screenNotLogged = false;
    }

    if (this.data && (this.data.widgetActive || this.data.botActive || this.data.level1Nodes)) {
      this.bubblesNumber = this.data.level1Nodes && this.data.level1Nodes.length ? this.data.level1Nodes.length : 0;
      this.showChatWidget = this.data.widgetActive || this.data.botActive || this.data.smartSearchActive;
    }

    this.locale = window.navigator.language.substring(0, 2) === "es" ? "es" : this.locale;
    this.$store.commit(types.UPDATE_USER, { locale: this.locale });

    this.setIsFromPWA(true);

    if (this.apiKey && this.apiKey.length > 0) {
      config.apiKey = this.isLobby ? this.lobbyApiKey : this.apiKey;
      config.baseApiKey = this.isLobby ? this.lobbyApiKey : this.apiKey;
    }
    if (this.appId && this.appId > 0) {
      config.appId = this.isLobby ? this.lobbyAppId : this.appId;
      config.baseAppId = this.isLobby ? this.lobbyAppId : this.appId;
    }

    config.apiKey = this.isLobby ? config.lobbyApiKey : config.baseApiKey;
    config.appId = this.isLobby ? config.lobbyAppId : config.baseAppId;

    this.$store.commit(types.IS_LOBBY, this.isLobby);
    this.$store.commit(types.IS_TV, this.isTV);

    // TODO: TS-1415 para que sirve? se pude mover a un método que sea auto-explicativo.
    const params = this.$router.currentRoute.value.query;
    if (params.showServicePopup && params.serviceType && params.serviceId) {
      this.getService({
        serviceType: params.serviceType,
        serviceId: params.serviceId
      }).then(service => {
        this.servicePopupServiceData = service;
        this.showServicePopup = true;
      });

      const query = Object.assign({}, this.$route.query);
      delete query.showServicePopup;
      delete query.serviceType;
      delete query.serviceId;
      this.$router.replace({ query });
    }

    if (this.pendingTermsConditions[this.establishment.id]) {
      this.showWelcome = true;
      this.emitShowWelcome();
    }

    this.startEstablishmentData();
    let createVisitorData = {
      locale: this.locale
    };

    // TODO: TS-1415 - Antonio o Alex quizás podrían refactorizar esto
    if (params.ref) {
      const ref = params.ref;
      const referralComponents = ref.split("_");

      if (referralComponents && referralComponents.length > 0 && referralComponents[0] === "UTM") {
        createVisitorData = { utm: this.extractMessageUtmReferral(ref) };
        this.startEstablishmentData();
        this.createVisitor(createVisitorData)
          .then(() => {
            this.updateUser();
            this.startUserData();
          })
          .catch(err => {
            console.error("Error creating visitor", err);
          });
      } else if (ref.indexOf("_DL") !== -1) {
        const referralUtm = this.extractMessageUtmReferral(ref);
        if (referralUtm) {
          createVisitorData = { utm: this.extractMessageUtmReferral(ref) };
        }
        this.startEstablishmentData();
        this.createVisitor(createVisitorData)
          .then(() => {
            this.updateUser();
            this.deepLink(ref);
          })
          .catch(err => {
            console.error("Error creating visitor", err);
          });
      } else {
        //TODO: this block will be deleted in the future and _preStayRefactor from deepLinks.js will do this process
        if (this.data?.frontpageStyle != frontpageStyles.FRONTPAGE_STYLE_WIDGETS) {
          const referralUtm = this.extractMessageUtmReferral(ref);
          if (referralUtm) {
            createVisitorData = { utm: this.extractMessageUtmReferral(ref) };
          }
          const block = function (self) {
            self.showLoadingInWelcome = true;
            self.togglePendingTerms(undefined); //setting this to false to show always the prestay welcome popup
            self
              .createPreGuestWithPS(ref, createVisitorData)
              .then(response => {
                self.updateUser();
                self.startUserData();

                if (
                  (!self.pendingTermsConditions ||
                    self.pendingTermsConditions[self.$store.state.establishment.id] !== false) &&
                  self.isLogged()
                ) {
                  self.showWelcome = true;
                  self.emitShowWelcome();
                  self.showLoadingInWelcome = false;
                  self.togglePendingTerms(true);
                } else {
                  self.autoLoginWithReferralFailed("LOGIN_ERROR");
                }
              })
              .catch(error => {
                //TODO: if it fails, it could happens because its an old prestay. This could be deleted after all prestays are in pms, few months after uploading TRS-813

                self.createVisitor(createVisitorData).then(() => {
                  self
                    .createPreGuestWithReferral(ref, true)
                    .then(response => {
                      self.updateUser();
                      self.startUserData();
                      if (
                        (!self.pendingTermsConditions ||
                          self.pendingTermsConditions[self.$store.state.establishment.id] !== false) &&
                        self.isLogged()
                      ) {
                        self.showWelcome = true;
                        self.emitShowWelcome();
                        self.showLoadingInWelcome = false;
                        self.togglePendingTerms(true);
                      } else {
                        self.autoLoginWithReferralFailed("LOGIN_ERROR");
                      }
                    })
                    .catch(error => {
                      console.error("Error", error);
                      const errorLog = error && error[0] ? error[0] : error ? error : "LOGIN_ERROR";
                      self.autoLoginWithReferralFailed(errorLog);
                    });
                });
              });
          };

          if (this.user.userKey && this.user.deviceKey) {
            this.showWelcome = true;
            this.emitShowWelcome();
            this.showLoadingInWelcome = true;
            this.$store.commit(types.UPDATE_USER, {
              establishments: { [this.establishment.id]: false }
            });
            this.logout()
              .then(() => {
                block(this);
              })
              .catch(error => {
                this.$store.commit(types.UPDATE_USER, {
                  userKey: false,
                  deviceKey: false
                });
                block(this);
              });
          } else {
            block(this);
          }
        }
      }
    } else {
      this.createVisitor(createVisitorData)
        .then(() => {
          this.updateUser();
          this.startUserData();
        })
        .catch(err => console.error("Error creating visitor", err));
    }

    this.checkFrontPushPermission();
    this.getEventsToFrontpage();
  }
};

export default Mixin;
