const UPDATE_MAIN_COLOR = "UPDATE_MAIN_COLOR";
const IS_FROM_PWA = "IS_FROM_PWA";
const IS_LOBBY = "IS_LOBBY";
const IS_TV = "IS_TV";
const IS_EMBEDDED_CHAT = "IS_EMBEDDED_CHAT";
const EMBEDDED_RETURN_URL = "EMBEDDED_RETURN_URL";
const ADD_TO_HOME = "ADD_TO_HOME";
const HAS_ENTERED = "HAS_ENTERED";
const CHANGE_DEVICE_UID = "CHANGE_DEVICE_UID";
const SET_EMBEDDED_TYPE = "SET_EMBEDDED_TYPE";
const IS_MONO_ESTABLISHMENT_APP = "IS_MONO_ESTABLISHMENT_APP";
const PRESTAY_CODE = "PRESTAY_CODE";
const SET_PUSH_TOKEN = "SET_PUSH_TOKEN";
const LOAD_FRONTPAGE = "LOAD_FRONTPAGE";
const LOAD_ESTABLISHMENT = "LOAD_ESTABLISHMENT";
const CHANGE_ESTABLISHMENT = "CHANGE_ESTABLISHMENT";
const SET_ESTABLISHMENT_HASH = "SET_ESTABLISHMENT_HASH";
const SET_ESTABLISHMENT_PUBLIC_ID = "SET_ESTABLISHMENT_PUBLIC_ID";
const SET_TOKEN_USER = "SET_TOKEN_USER";
const SET_DIGITAL_KEY_FIELD = "SET_DIGITAL_KEY_FIELD";
const UPDATE_USER = "UPDATE_USER";
const CREATE_VISITOR = "CREATE_VISITOR";
const CREATE_GUEST = "CREATE_GUEST";
const GET_STRINGS = "GET_STRINGS";
const GET_SERVICES = "GET_SERVICES";
const GET_SERVICE_ITEMS = "GET_SERVICE_ITEMS";
const GET_SERVICE_ITEM_CATEGORIES = "GET_SERVICE_ITEM_CATEGORIES";
const GET_TRIPADVISOR_SERVICES = "GET_TRIPADVISOR_SERVICES";
const GET_CATEGORIES = "GET_CATEGORIES";
const GET_PRODUCTS = "GET_PRODUCTS";
const GET_PRODUCT_CATEGORIES = "GET_PRODUCT_CATEGORIES";
const SET_ACTIVITY = "SET_ACTIVITY";
const LOGOUT = "LOGOUT";
const USER_INBOX = "USER_INBOX";
const SET_USERKEY = "SET_USERKEY";
const UPDATE_REQUEST = "UPDATE_REQUEST";
const MARK_AS_READ = "MARK_AS_READ";
const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
const CHANGE_NOTIFICATIONS = "CHANGE_NOTIFICATIONS";
const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";
const DISMISS_NOTIFICATIONS = "DISMISS_NOTIFICATIONS";
const DELETE_NOTIFICATION_ONLY_ONE = "DELETE_NOTIFICATION_ONLY_ONE";
const DELETE_ALERT = "DELETE_ALERT";
const DOWNLOAD_BANNER = "DOWNLOAD_BANNER";
const FRONTPAGE_ICON = "FRONTPAGE_ICON";
const SURVEY = "SURVEY";
const SURVEYS = "SURVEYS";
const SURVEY_REPLIES = "SURVEY_REPLIES";
const SURVEY_EXTERNAL_REVIEWS = "SURVEY_EXTERNAL_REVIEWS";
const SURVEY_EXTERNAL_REVIEWS_ALREADY_SHOWN = "SURVEY_EXTERNAL_REVIEWS_ALREADY_SHOWN";
const SURVEY_SHOW_FRONTPAGE = "SURVEY_SHOW_FRONTPAGE";
const STORE_CHAT_MESSAGES = "STORE_CHAT_MESSAGES";
const IS_DEEP_LINK = "IS_DEEP_LINK";
const ADD_CHAT_MESSAGE = "ADD_CHAT_MESSAGE";
const REMOVE_LAST_MESSAGE = "REMOVE_LAST_MESSAGE";
const SET_SURVEYS_SHOW = "SET_SURVEYS_SHOW";
const STORE_TWILIO_TOKEN = "STORE_TWILIO_TOKEN";
const STORE_CHANNEL_MEMBER = "STORE_CHANNEL_MEMBER";
const PENDING_TERMS_CONDITIONS = "PENDING_TERMS_CONDITIONS";
const CUSTOM_SERVICES = "CUSTOM_SERVICES";
const CHANGE_API_KEY = "CHANGE_API_KEY";
const CHANGE_OS = "CHANGE_OS";
const CHANGE_APP_ID = "CHANGE_APP_ID";
const EVENTS = "EVENTS";
const REMOVE_EVENT = "REMOVE_EVENT";
const PUBLIC_EVENTS = "PUBLIC_EVENTS";
const NEW_EVENT_ID = "NEW_EVENT_ID";
const DESKTOP_RIGHT_PANEL_HISTORY = "DESKTOP_RIGHT_PANEL_HISTORY";
const HIDE_SURVEY = "HIDE_SURVEY";
const SET_BOT_TOKEN = "SET_BOT_TOKEN";
const SET_CMS_TOKEN = "SET_CMS_TOKEN";
const EVENTS_CALENDAR = "EVENTS_CALENDAR";
const TOKEN_API_CLUSTER = "TOKEN_API_CLUSTER";
const TOKEN_REFRESH_API_CLUSTER = "TOKEN_REFRESH_API_CLUSTER";
const SHOW_DESKTOP_CHAT_SCREEN = "SHOW_DESKTOP_CHAT_SCREEN";
const ENABLED_MODULES = "ENABLED_MODULES";
const DYNAMIC_FRONTPAGE = "DYNAMIC_FRONTPAGE";
const ELASTIC_RESULTS = "ELASTIC_RESULTS";
const USER_USED_CHAT = "USER_USED_CHAT";
const ESTABLISHMENT_ID_CLUSTER = "ESTABLISHMENT_ID_CLUSTER";
const APP_IS_DISABLED = "APP_IS_DISABLED";
const SMART_SEARCH_TEXT = "SMART_SEARCH_TEXT";
const SET_GROUPING_TAB = "SET_GROUPING_TAB";
const SET_CITY_GUIDE_TAB = "SET_CITY_GUIDE_TAB";
const SET_GROUPING_POINT_INDEX = "SET_GROUPING_POINT_INDEX";
const SET_GROUPING_VIEW = "SET_GROUPING_VIEW";
const SET_CITY_GUIDE_VIEW = "SET_CITY_GUIDE_VIEW";
const RESET_GROUPING = "RESET_GROUPING";
const SET_WELCOME_SURVEY_DATE = "SET_WELCOME_SURVEY_DATE";
const ADD_ITEM_TO_MY_SCHEDULE = "ADD_ITEM_TO_MY_SCHEDULE";
const REMOVE_ITEM_FROM_MY_SCHEDULE = "REMOVE_ITEM_FROM_MY_SCHEDULE";
const ADD_ITEM_TO_MY_VISITOR_BOOKINGS = "ADD_ITEM_TO_MY_VISITOR_BOOKINGS";
const CONGRESS_ID_REDIRECT = "CONGRESS_ID_REDIRECT";
const DELETE_INFO_ESTABLISHMENT = "DELETE_INFO_ESTABLISHMENT";
const NEW_CONGRESS = "NEW_CONGRESS";
const SET_LAST_READ_REQUEST_CHAT_MESSAGES = "SET_LAST_READ_REQUEST_CHAT_MESSAGES";
const STORE_ONE_SERVICE = "STORE_ONE_SERVICE";
const ROOM_SERVICE_CART_CACHE = "ROOM_SERVICE_CART_CACHE";
const SERVICE_ORDERING_FUNNEL_CACHE = "SERVICE_ORDERING_FUNNEL_CACHE";
const DELETE_SERVICE_ORDERING_FUNNEL_CACHE = "DELETE_SERVICE_ORDERING_FUNNEL_CACHE";
const NOTIFICATIONS_TIMES_SHOWED = "NOTIFICATIONS_TIMES_SHOWED";
const NOTIFICATIONS_TIMESTAMP_POPUP = "NOTIFICATIONS_TIMESTAMP_POPUP";
const INBOX_TIMES_SHOWED = "INBOX_TIMES_SHOWED";
const INBOX_TIMESTAMP_POPUP = "INBOX_TIMESTAMP_POPUP";
const APP_WELCOME_SHOW = "APP_WELCOME_SHOW";
const WELCOME_SHOW_VISIBLE = "WELCOME_SHOW_VISIBLE";
const WELCOME_SHOW_ACTIVE = "WELCOME_SHOW_ACTIVE";
const DEEPLINK_GOTOSECTION = "DEEPLINK_GOTOSECTION";
const STORE_FRONTPAGE_DATA = "STORE_FRONTPAGE_DATA";
const UTM_SOURCE = "UTM_SOURCE";
const UTM_CAMPAIGN = "UTM_CAMPAIGN";
const UTM_MEDIUM = "UTM_MEDIUM";
const RESET_USER_STORE = "RESET_USER_STORE";
const RESET_APIKEY_TO_DEFAULT = "RESET_APIKEY_TO_DEFAULT";
const STORE_REQUEST_CHAT_OFFLINE_MESSAGE = "STORE_REQUEST_CHAT_OFFLINE_MESSAGE";
const STARTER_PLAN = "STARTER_PLAN";
const RESET_STORE = "RESET_STORE";
const STORE_CATALOGUES = "STORE_CATALOGUES";
const CATALOGUES_SEGMENTATION = "CATALOGUES_SEGMENTATION";
const ADD_CITY_GUIDE_BOOKMARK = "ADD_CITY_GUIDE_BOOKMARK";
const REMOVE_CITY_GUIDE_BOOKMARK = "REMOVE_CITY_GUIDE_BOOKMARK";
const REMOVE_CITY_GUIDE_BOOKMARK_NOTE = "REMOVE_CITY_GUIDE_BOOKMARK_NOTE";
const SET_MINICLUB_TUTOR_INFO = "SET_MINICLUB_TUTOR_INFO";
const SET_DIRECTORY_TAB = "SET_DIRECTORY_TAB";
const SET_GDPR_CONFIGURATION = "SET_GDPR_CONFIGURATION";
const SET_PROMO_HAS_BEEN_SEEN = "SET_PROMO_HAS_BEEN_SEEN";
const INTERFACE_LANGUAGE = "INTERFACE_LANGUAGE";
const SET_TRACKER_MODULE = "SET_TRACKER_MODULE";
const DELETE_TRACKER_MODULE = "DELETE_TRACKER_MODULE";
const SET_APP_URLS = "SET_APP_URLS";
const SET_VERTICAL_SCREEN = "SET_VERTICAL_SCREEN";
const SET_VERTICAL_SCREEN_WITH_VIDEO = "SET_VERTICAL_SCREEN_WITH_VIDEO";
const SET_DATE_NOW = "SET_DATE_NOW";
const SET_USER_LOCALE = "SET_USER_LOCALE";
const STORE_FRONTPAGE_SCROLL = "STORE_FRONTPAGE_SCROLL";
const STORE_SHOW_OPEN_OBJECT = "STORE_SHOW_OPEN_OBJECT";
const ADD_EVENT_PRE_LOGIN = "ADD_EVENT_PRE_LOGIN";
const SET_MULTI_ESTABLISHMENT = "SET_MULTI_ESTABLISHMENT";
const SET_MULTI_ESTABLISHMENT_PUBLIC_ID = "SET_MULTI_ESTABLISHMENT_PUBLIC_ID";
const SET_SCHEDULES_SERVICES = "SET_SCHEDULES_SERVICES";
const EMBED_SAFEAREA = "EMBED_SAFEAREA";
const SET_POLICY_TEXT = "SET_POLICY_TEXT";

export {
  UPDATE_MAIN_COLOR,
  IS_FROM_PWA,
  IS_LOBBY,
  IS_TV,
  IS_EMBEDDED_CHAT,
  ADD_TO_HOME,
  EMBEDDED_RETURN_URL,
  IS_DEEP_LINK,
  HAS_ENTERED,
  CHANGE_DEVICE_UID,
  SET_PUSH_TOKEN,
  SET_EMBEDDED_TYPE,
  IS_MONO_ESTABLISHMENT_APP,
  PRESTAY_CODE,
  LOAD_FRONTPAGE,
  LOAD_ESTABLISHMENT,
  CHANGE_ESTABLISHMENT,
  SET_ESTABLISHMENT_HASH,
  SET_ESTABLISHMENT_PUBLIC_ID,
  SET_TOKEN_USER,
  SET_DIGITAL_KEY_FIELD,
  UPDATE_USER,
  CREATE_VISITOR,
  CREATE_GUEST,
  GET_STRINGS,
  GET_SERVICES,
  GET_SERVICE_ITEMS,
  GET_SERVICE_ITEM_CATEGORIES,
  GET_TRIPADVISOR_SERVICES,
  GET_CATEGORIES,
  GET_PRODUCT_CATEGORIES,
  CHANGE_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  DISMISS_NOTIFICATION,
  DISMISS_NOTIFICATIONS,
  DELETE_NOTIFICATION_ONLY_ONE,
  SET_MINICLUB_TUTOR_INFO,
  DELETE_ALERT,
  LOGOUT,
  GET_PRODUCTS,
  USER_INBOX,
  SET_USERKEY,
  UPDATE_REQUEST,
  MARK_AS_READ,
  SET_ACTIVITY,
  DOWNLOAD_BANNER,
  FRONTPAGE_ICON,
  STORE_CHAT_MESSAGES,
  ADD_CHAT_MESSAGE,
  REMOVE_LAST_MESSAGE,
  SET_SURVEYS_SHOW,
  SURVEY,
  SURVEYS,
  SURVEY_REPLIES,
  SURVEY_EXTERNAL_REVIEWS,
  SURVEY_EXTERNAL_REVIEWS_ALREADY_SHOWN,
  SURVEY_SHOW_FRONTPAGE,
  STORE_TWILIO_TOKEN,
  STORE_CHANNEL_MEMBER,
  PENDING_TERMS_CONDITIONS,
  CUSTOM_SERVICES,
  CHANGE_API_KEY,
  CHANGE_APP_ID,
  EVENTS,
  REMOVE_EVENT,
  PUBLIC_EVENTS,
  NEW_EVENT_ID,
  DESKTOP_RIGHT_PANEL_HISTORY,
  HIDE_SURVEY,
  SET_WELCOME_SURVEY_DATE,
  SET_BOT_TOKEN,
  SET_CMS_TOKEN,
  EVENTS_CALENDAR,
  TOKEN_API_CLUSTER,
  TOKEN_REFRESH_API_CLUSTER,
  SHOW_DESKTOP_CHAT_SCREEN,
  ENABLED_MODULES,
  DYNAMIC_FRONTPAGE,
  ELASTIC_RESULTS,
  USER_USED_CHAT,
  ESTABLISHMENT_ID_CLUSTER,
  APP_IS_DISABLED,
  SMART_SEARCH_TEXT,
  SET_GROUPING_TAB,
  SET_CITY_GUIDE_TAB,
  SET_GROUPING_POINT_INDEX,
  SET_GROUPING_VIEW,
  SET_CITY_GUIDE_VIEW,
  RESET_GROUPING,
  ADD_ITEM_TO_MY_SCHEDULE,
  REMOVE_ITEM_FROM_MY_SCHEDULE,
  ADD_ITEM_TO_MY_VISITOR_BOOKINGS,
  CONGRESS_ID_REDIRECT,
  DELETE_INFO_ESTABLISHMENT,
  NOTIFICATIONS_TIMES_SHOWED,
  NOTIFICATIONS_TIMESTAMP_POPUP,
  INBOX_TIMES_SHOWED,
  INBOX_TIMESTAMP_POPUP,
  NEW_CONGRESS,
  SET_LAST_READ_REQUEST_CHAT_MESSAGES,
  STORE_ONE_SERVICE,
  ROOM_SERVICE_CART_CACHE,
  SERVICE_ORDERING_FUNNEL_CACHE,
  DELETE_SERVICE_ORDERING_FUNNEL_CACHE,
  APP_WELCOME_SHOW,
  WELCOME_SHOW_VISIBLE,
  WELCOME_SHOW_ACTIVE,
  DEEPLINK_GOTOSECTION,
  STORE_FRONTPAGE_DATA,
  UTM_SOURCE,
  UTM_CAMPAIGN,
  UTM_MEDIUM,
  RESET_USER_STORE,
  RESET_APIKEY_TO_DEFAULT,
  STORE_REQUEST_CHAT_OFFLINE_MESSAGE,
  STARTER_PLAN,
  RESET_STORE,
  STORE_CATALOGUES,
  CATALOGUES_SEGMENTATION,
  ADD_CITY_GUIDE_BOOKMARK,
  REMOVE_CITY_GUIDE_BOOKMARK,
  REMOVE_CITY_GUIDE_BOOKMARK_NOTE,
  SET_DIRECTORY_TAB,
  SET_GDPR_CONFIGURATION,
  SET_PROMO_HAS_BEEN_SEEN,
  INTERFACE_LANGUAGE,
  SET_TRACKER_MODULE,
  DELETE_TRACKER_MODULE,
  SET_APP_URLS,
  SET_VERTICAL_SCREEN,
  SET_VERTICAL_SCREEN_WITH_VIDEO,
  SET_DATE_NOW,
  CHANGE_OS,
  SET_USER_LOCALE,
  STORE_FRONTPAGE_SCROLL,
  STORE_SHOW_OPEN_OBJECT,
  ADD_EVENT_PRE_LOGIN,
  SET_MULTI_ESTABLISHMENT,
  SET_MULTI_ESTABLISHMENT_PUBLIC_ID,
  SET_SCHEDULES_SERVICES,
  EMBED_SAFEAREA,
  SET_POLICY_TEXT
};
