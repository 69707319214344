import config from "./config";
import { isEmbedPWA, urlB64ToUint8Array } from "./services/utils";
import axios from "axios";
import { EventBus } from "./services/eventBus";
import store from "./store";
import { register } from "register-service-worker";

var swRegistration = null;

const setRegistration = hashIdUrl => {
  if ("serviceWorker" in navigator) {
    let swUrl = "";

    if (isEmbedPWA()) {
      swUrl = "/public/sw.js";
    } else if (store.state && store.state.establishmentHash) {
      swUrl = "/public/sw.js?hashId=" + store.state.establishmentHash;
    } else if (hashIdUrl) {
      swUrl = "/public/sw.js?hashId=" + hashIdUrl;
    } else {
      swUrl = "/public/sw.js";
    }

    register(swUrl, {
      ready(registration) {
        //ready state doesnt work in mobile device. we use registered method
      },
      registered(registration) {
        swRegistration = registration;

        EventBus.$emit("startingSubscription");
      },
      cached(registration) {},
      updatefound(registration) {},
      updated(registration) {},
      offline() {},
      error(error) {
        console.error("INDEXPWA RSW ---  Error during service worker registration:", error);
      }
    });
  }
};

const updateSubscriptionOnServer = (subscription, state) => {
  if (!state?.user) {
    return console.error("state.user is not defined");
  }

  //store in db
  return axios({
    method: "post",
    url: `${config.currentPWAApiHost}/api/push`,
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      establishment: state.establishment.id,
      userId: state.user.uid,
      deviceUid: state.deviceUid,
      subscription: subscription
    }
  });
};

const subscribeUser = registration => {
  try {
    const applicationServerKey = urlB64ToUint8Array(config.applicationServer.PublicKey);
    return swRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: applicationServerKey
    });
  } catch (e) {
    console.error(e);
  }
};

// TODO in another place, legacy code for ga. it doesnt work with the new sw.
const handleClickOnNotification = cb => {};

const startSubscription = async state => {
  try {
    if (!swRegistration?.pushManager) {
      throw Error("swRegistration.pushManager not found");
    }

    const subscription = await swRegistration.pushManager.getSubscription();

    !subscription ? EventBus.$emit("serviceWorkerNotificationPopup") : updateSubscriptionOnServer(subscription, state);

    return "$subscription";
  } catch (e) {
    throw Error(e);
  }
};
const showFirstPushPermission = async state => {
  EventBus.$emit("serviceWorkerStartSubscription");

  try {
    if (swRegistration === null) {
      return;
    }

    const subscription = await subscribeUser(swRegistration);
    updateSubscriptionOnServer(subscription, state);
  } catch (e) {
    console.error(e);
  }
};

const checkPushPermission = () => {
  //avoided push management in IOs devices
  if (/iphone|ipod|ipad/i.test(window?.navigator?.userAgent)) {
    return true;
  }

  if (!("Notification" in window)) {
    return false;
  }

  return Notification?.permission === "granted";
};

//https://www.chromestatus.com/features/6443143280984064
const showPushPermission = () => {
  if (!checkPushPermission() && "Notification" in window && Notification?.permission !== "granted") {
    return true;
  }

  return false;
};

function checkNotifications(store) {
  return Notification.requestPermission(async status => {
    status === "denied"
      ? console.error(`INDEXPWA requestPermission: ${status}`)
      : await showFirstPushPermission(store.state);

    store.commit("notificationsModule/setNotificationPermission", status);
    return status !== "denied";
  });
}

export {
  setRegistration,
  subscribeUser,
  startSubscription,
  handleClickOnNotification,
  checkPushPermission,
  showPushPermission,
  showFirstPushPermission,
  checkNotifications
};
