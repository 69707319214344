import { mapActions } from "vuex";
import bookingEnabled from "@/utils/enums/bookingEnabled";
import { translate, getImageUrl } from "@/services/utils";

const SPACE_BOOKING = bookingEnabled.ENABLED_SPACES;
const SPACE_BOOKING_AND_ORDER = bookingEnabled.ENABLED_SPACES_BOOKING_AND_ORDERING;
const SPACE_ORDER = bookingEnabled.ENABLED_SPACES_ONLY_ORDERING;
const SPACE_PRODUCT_WITH_TURNS = bookingEnabled.ENABLED_SPACES_PRODUCTS_WITH_TURNS;

const Mixin = {
  data() {
    return {
      spaceConfiguration: false,
      spaceRestrictions: false,
      multiserviceSpaceRestrictions: false
    };
  },
  methods: {
    ...mapActions(["getSpaceConfigurationByService"]),
    canReserveVisitor() {
      if (this.spaceRestrictions?.visitors?.active === true && this.spaceRestrictions?.visitors?.mobileApp === true) {
        return true;
      }
      return false;
    },
    hasRestrictionsActivated() {
      if (this.spaceRestrictions?.segmentation === true) {
        //only watch first element because active is the same in all objects
        if (this.spaceRestrictions?.bookingByDay) {
          if (Object.values(this.spaceRestrictions.bookingByDay)[0]?.active === true) {
            return true;
          }
        }
        if (this.spaceRestrictions?.bookingByStayDays) {
          if (Object.values(this.spaceRestrictions.bookingByStayDays)[0]?.active === true) {
            return true;
          }
        }
        if (this.spaceRestrictions?.bookingPax) {
          if (Object.values(this.spaceRestrictions.bookingPax)[0]?.active === true) {
            return true;
          }
        }
      }
      return false;
    },
    hasMultiServiceRestrictionsActivated() {
      if (this.multiserviceSpaceRestrictions) {
        //we watch multiservice object. Only watch first element because active is the same in all objects
        if (this.multiserviceSpaceRestrictions?.multiservice?.bookingByDay) {
          if (Object.values(this.multiserviceSpaceRestrictions.multiservice.bookingByDay)[0]?.active === true) {
            return true;
          }
        }
        if (this.multiserviceSpaceRestrictions?.multiservice?.bookingByStayDays) {
          if (Object.values(this.multiserviceSpaceRestrictions.multiservice.bookingByStayDays)[0]?.active === true) {
            return true;
          }
        }
        if (this.multiserviceSpaceRestrictions?.multiservice?.bookingPax) {
          if (Object.values(this.multiserviceSpaceRestrictions.multiservice.bookingPax)[0]?.active === true) {
            return true;
          }
        }
      }
      return false;
    },
    visitorCouldReserveSpace(serviceId) {
      return this.isSpacesBookingService(serviceId) || this.isSpacesProductWithTurns(serviceId);
    },
    visitorCouldOrderSpace(service) {
      return this.isSpacesOrderCheck(service);
    },
    isSpaceService(service) {
      if (service && service.bookingSystem === 11 && service.bookingEnabled) {
        return true;
      }
      return false;
    },
    getSpaceConfiguration(serviceId) {
      this.getSpaceConfigurationByService({ serviceId: serviceId })
        .then(response => {
          if (response) {
            this.spaceConfiguration = response;
          }
        })
        .catch(error => {});
    },
    isSpacesPOSCheck(service) {
      if (
        service &&
        service.externalId &&
        service.externalId.integration &&
        service.externalId.integration === "quopos"
      ) {
        return true;
      }
      return false;
    },
    //bookingEnabled 4 or 5
    isSpacesOnlyBookingService(service) {
      if (
        service &&
        service.bookingEnabled &&
        service.bookingSystem &&
        service.bookingSystem == 11 &&
        service.bookingEnabled == SPACE_BOOKING
      ) {
        return true;
      }
      return false;
    },
    //bookingEnabled 5 or 6
    isSpacesOrderCheck(service) {
      if (
        service &&
        service.bookingEnabled &&
        service.bookingSystem &&
        service.bookingSystem == 11 &&
        (service.bookingEnabled == SPACE_ORDER || service.bookingEnabled == SPACE_BOOKING_AND_ORDER)
      ) {
        return true;
      }
      return false;
    },
    //bookingEnabled 4 or 5
    isSpacesBookingService(service) {
      if (
        service &&
        service.bookingEnabled &&
        service.bookingSystem &&
        service.bookingSystem == 11 &&
        (service.bookingEnabled == SPACE_BOOKING || service.bookingEnabled == SPACE_BOOKING_AND_ORDER)
      ) {
        return true;
      }
      return false;
    },
    //bookingEnabled 5
    isSpacesOrderAndBookingCheck(service) {
      if (
        service &&
        service.bookingEnabled &&
        service.bookingSystem &&
        service.bookingSystem == 11 &&
        service.bookingEnabled === SPACE_BOOKING_AND_ORDER
      ) {
        return true;
      }
      return false;
    },
    //bookingEnabled 6
    isSpacesOnlyOrdersCheck(service) {
      if (
        service &&
        service.bookingEnabled &&
        service.bookingSystem &&
        service.bookingSystem == 11 &&
        service.bookingEnabled === SPACE_ORDER
      ) {
        return true;
      }
      return false;
    },
    //bookingEnabled 7
    isSpacesProductWithTurns(service) {
      if (
        service &&
        service.bookingEnabled &&
        service.bookingSystem &&
        service.bookingSystem == 11 &&
        service.bookingEnabled === SPACE_PRODUCT_WITH_TURNS
      ) {
        return true;
      }
      return false;
    },
    showCatalogueSpacesProductWithTurns(service) {
      const serviceTypeArray = ["sport", "balinese_bed", "premiumservice"];
      if (
        service?.typeString &&
        this.isSpacesProductWithTurns(service) &&
        serviceTypeArray.includes(service.typeString)
      ) {
        return true;
      }
      return false;
    },
    fillSpacesBookingData(data, service, establishment) {
      // Fill additional data to any spaces booking needed for the TransactionCOnfirmation page.
      if (!data || !service || !establishment) {
        console.error("All fillSpacesBookingData() function properties are mandatory");
        return;
      }

      data.serviceName = translate(service.translatableName);
      data.itemName = data.serviceName;
      data.imageUrl = getImageUrl(service.photographs[0], null, "_ld");
      data.currencySymbol = service.currencyData?.symbol;
      data.bookingConfirmAutoReplyEmail = service.bookingConfirmAutoReplyEmail;
      data.bookingAutoReplyEmail = service.bookingAutoReplyEmail;
      data.establishment = establishment.id;
      data.serviceType = service.typeString;

      if (data?.pax?.total) {
        data.totalPax = data.pax.total;
      }
      if (data?.selectedTurn?.id) {
        data.turn = data.selectedTurn.id;
      }
      if (data?.bookingTime) {
        data.bookingDate = data.bookingTime.slice(0, 10);
      }
      if (data?.selectedTurn?.starts) {
        data.bookingHour = data.selectedTurn.starts;
      }
      if (service?.cmsModule) {
        data.cmsModule = service.cmsModule;
      }
      if (data?.comments?.length) {
        if (data.comments[0]?.type && data.comments[0].type == "guest" && data.comments[0].text) {
          data.comment = data.comments[0].text;
        }
      }

      data.bookingSystem = 11;
      data.bookingEnabled = service.bookingEnabled;

      return data;
    }
  }
};

export default Mixin;
