import config from "../config";
import request from "@/services/request";

const createEnquiry = (state, success, fail, service, itemType, itemId, comment) => {
  const url = config.mobileApiHost + "/v1/leads/inapp/create";
  request({
    method: "post",
    url: url,
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      itemType: itemType,
      itemId: itemId,
      service: service,
      comment: comment
    },
    params: {
      apikey: config.apiKey,
      userkey: state.user.userKey,
      devicekey: state.user.deviceKey,
      lastupdate: state.userInbox ? state.userInbox.lastUpdate : 0,
      establishment: state.establishment.publicId
    }
  })
    .then(success)
    .catch(fail);
};

export { createEnquiry };
