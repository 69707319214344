import config from "../config";
import request from "@/services/request";

const getCongressCalendar = (state, success, fail, congressId) => {
  request({
    method: "get",
    url: config.cmsApiHost + "/api/v1/mycongress/" + congressId + "/calendar",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer,
      "Stay-Establishment": state.establishmentHash
    },
    data: {}
  })
    .then(success)
    .catch(fail);
};

const linkCongress = (state, success, fail, codeOrId, isPublic) => {
  let param = "";
  if (isPublic) {
    param = "congressId";
  } else {
    param = "congressCode";
  }

  const apikey = config.apiKey;
  const establishmentId = state.establishment.publicId;
  const userkey = state.user.userKey;
  const deviceKey = state.user.deviceKey;

  let urlParam =
    config.mobileApiHost +
    "/v1/users/linkcongress?" +
    "apikey=" +
    apikey +
    "&userkey=" +
    userkey +
    "&establishment=" +
    establishmentId;

  if (deviceKey) {
    urlParam = urlParam + "&devicekey=" + deviceKey;
  }

  const data = {};
  data[param] = codeOrId;

  request({
    method: "post",

    url: urlParam,
    headers: {
      "Content-Type": "application/json"
    },
    data
  })
    .then(success)
    .catch(fail);
};

const unlinkCongress = (state, success, fail, codeOrId, isPublic) => {
  let param = "";
  if (isPublic) {
    param = "congressId";
  } else {
    param = "congressCode";
  }

  const apikey = config.apiKey;
  const establishmentId = state.establishment.publicId;
  const userkey = state.user.userKey;

  const deviceKey = state.user.deviceKey;

  let urlParam =
    config.mobileApiHost +
    "/v1/users/unlinkcongress?" +
    "apikey=" +
    apikey +
    "&userkey=" +
    userkey +
    "&establishment=" +
    establishmentId;

  if (deviceKey) {
    urlParam = urlParam + "&devicekey=" + deviceKey;
  }

  const data = {};
  data[param] = codeOrId;

  request({
    method: "post",

    url: urlParam,
    headers: {
      "Content-Type": "application/json"
    },
    data
  })
    .then(success)
    .catch(fail);
};

const getEventList = (state, success, fail) => {
  let apikey = config.apiKey;

  if (state.isLobby) {
    //needed cause of backend logic NOT_AUTHORIZED_ANONYMOUS_DEVICES
    //TODO: we should delete this if they modify it in the future
    apikey = config.baseApiKey;
  }

  const complexId = state.establishment.id;

  const urlParam = config.mobileApiHost + "/v1/congresses/?" + "apikey=" + apikey + "&establishment=" + complexId;

  request({
    method: "post",

    url: urlParam,
    headers: {
      "Content-Type": "application/json"
    },
    data: {}
  })
    .then(success)
    .catch(fail);
};

//not used..
const getEventInfo = (state, success, fail, idEvent) => {
  const apikey = config.apiKey;
  const complexId = state.establishment.id;
  const userkey = state.user.userKey;
  const deviceKey = state.user.deviceKey;

  let urlParam =
    config.mobileApiHost +
    "/v1/myevents/" +
    idEvent +
    "/detail?" +
    "apikey=" +
    apikey +
    "&establishment=" +
    complexId +
    "&userkey=" +
    userkey;

  if (deviceKey) {
    urlParam = urlParam + "&devicekey=" + deviceKey;
  }

  request({
    method: "post",

    url: urlParam,
    headers: {
      "Content-Type": "application/json"
    },
    data: {}
  })
    .then(success)
    .catch(fail);
};

const getCongressList = (state, success, fail) => {
  request({
    method: "get",
    url: config.cmsApiHost + "/api/v1/mycongress",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer,
      "Stay-Establishment": state.establishmentHash
    },
    data: {}
  })
    .then(success)
    .catch(fail);
};

const getCongress = (state, success, fail, congressId) => {
  request({
    method: "get",
    url: config.cmsApiHost + "/api/v1/mycongress/" + congressId,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer,
      "Stay-Establishment": state.establishmentHash
    },
    data: {}
  })
    .then(success)
    .catch(fail);
};

const getCongressActivityDetail = (state, success, fail, data) => {
  request({
    method: "get",
    url: config.cmsApiHost + "/api/v1/mycongress/" + data.congressId + "/activity/" + data.activityId,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.cmsApiHostCredentials.bearer,
      "Stay-Establishment": state.establishmentHash
    },
    data: {}
  })
    .then(success)
    .catch(fail);
};

export {
  // getCongressActivity,
  getCongressCalendar,
  linkCongress,
  unlinkCongress,
  getEventList,
  getCongressList,
  getEventInfo,
  getCongress,
  getCongressActivityDetail
};
