import { getLocaleWithInterfaceLanguage } from "@/services/utils.js";

const getDateFullLocaleString = d => {
  if (!d) {
    return "";
  }
  return d.toLocaleString(getLocaleWithInterfaceLanguage(), {
    timeZone: "UTC",
    weekday: "long",
    month: "long",
    day: "2-digit",
    year: "numeric"
  });
};

const getDateFullLocaleStringWithoutYear = d => {
  if (!d) {
    return "";
  }
  return d.toLocaleString(getLocaleWithInterfaceLanguage(), {
    timeZone: "UTC",
    weekday: "long",
    month: "long",
    day: "2-digit"
  });
};

const getShortMonthDayString = d => {
  return d.toLocaleString(getLocaleWithInterfaceLanguage(), {
    month: "short",
    day: "2-digit"
  });
};

const getWeekdayLocaleString = d => {
  if (!d) {
    return "";
  }
  return d.toLocaleString(getLocaleWithInterfaceLanguage(), {
    weekday: "long"
  });
};

export { getDateFullLocaleString, getDateFullLocaleStringWithoutYear, getWeekdayLocaleString, getShortMonthDayString };
