import config from "../config";
import * as auth from "./auth";
import store from "../store";
import request from "@/services/request";
const UUID_V4_REG_EXP = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/;

const decodeHash = (state, success, fail, establishmentId, essentialBearer) => {
  request.get("/api/codes/csrf-token").then(
    response => {
      //getting auth bearer for cluster api
      auth.getClusterToken(establishmentId).then(
        res => {
          success(res);
        },
        err => {
          console.error(err);
          // Force create new cluster token if failed while refreshing
          if (store.state.tokenRefreshApiCluster) {
            auth.getClusterToken(establishmentId, true, essentialBearer).then(
              res => {
                success(res);
              },
              err => {
                console.error(err);
                fail(err);
              }
            );
          } else {
            fail(err);
          }
        }
      );
    },
    err => {
      console.error(err);
    }
  );
};

const decodeHashCongress = (state, success, fail, congressId) => {
  request
    .get("/api/codes/getCongress/" + congressId)
    .then(success)
    .catch(fail);
};

function callGetCodes(state, success, fail, establishmentId, response) {
  request({
    method: "post",
    url: "/api/codes/get",
    headers: {
      "X-CSRF-TOKEN": response.data.csrfToken
    },
    data: { id: establishmentId },
    withCredentials: true
  })
    .then(success)
    .catch(fail);
}

const getPrestayUrlInfo = (state, success, fail, code, establishmentId) => {
  const essentialBearer = false;
  request.get("/api/codes/csrf-token").then(
    response => {
      //getting auth bearer for cluster api
      auth.getClusterToken(establishmentId).then(
        res => {
          request({
            method: "get",
            url: config.cmsApiHost + "/api/v1/preStay/" + code + "/params/url",
            headers: {
              Authorization: "Bearer " + config.cmsApiHostCredentials.bearer,
              "Stay-Establishment": "Qw58"
            }
          })
            .then(response => {
              if (response?.data?.data) {
                if (establishmentId.match(UUID_V4_REG_EXP)) {
                  response.data.data.establishmentPublicId = establishmentId;
                  success(response);
                } else if (response.data.data.id) {
                  request
                    .get("/api/codes/get/" + response.data.data.id)
                    .then(responseDecode => {
                      if (responseDecode.data && responseDecode.data.establishment) {
                        response.data.data.decodedEstablishment = responseDecode.data.establishment;
                      }
                      success(response);
                    })
                    .catch(fail);
                }
              } else {
                success(response);
              }
            })
            .catch(fail);
        },
        err => {
          if (store.state.tokenRefreshApiCluster) {
            auth.getClusterToken(false, true, essentialBearer).then(
              res => {
                request({
                  method: "get",
                  url: config.cmsApiHost + "/api/v1/preStay/" + code + "/params/url",
                  headers: {
                    Authorization: "Bearer " + config.cmsApiHostCredentials.bearer,
                    "Stay-Establishment": "Qw58"
                  }
                })
                  .then(response => {
                    if (response.data && response.data.data && response.data.data.id) {
                      request
                        .get("/api/codes/get/" + response.data.data.id)
                        .then(responseDecode => {
                          if (responseDecode.data && responseDecode.data.establishment) {
                            response.data.data.decodedEstablishment = responseDecode.data.establishment;
                          }
                          success(response);
                        })
                        .catch(fail);
                    } else {
                      success(response);
                    }
                  })
                  .catch(fail);
              },
              err => {
                console.error(err);
                fail(err);
              }
            );
          } else {
            fail(err);
          }
        }
      );
    },
    err => {
      console.error(err);
    }
  );
};

export { decodeHash, getPrestayUrlInfo, decodeHashCongress };
