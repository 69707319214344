import config from "../config";
import request from "@/services/request";
import * as auth from "./auth";

const getMultiEstablishmentPublicId = (state, serviceId) => {
  return state.multiEstablishmentPublicId
    ? state.multiEstablishmentPublicId
    : state.multiEstablishment && state.multiEstablishment.length >= 1
      ? state.multiEstablishment.find(item => item.id.toString() === serviceId.toString())?.establishmentPublicId
      : null;
};

const reserveProduct = (state, success, fail, bookingData) => {
  request({
    method: "post",
    url: config.apiHost + "/webhooks/bookings/creation-demand",
    headers: {
      "Content-Type": "application/json"
    },
    data: bookingData
  })
    .then(success)
    .catch(fail);
};

const reserveService = (state, success, fail, bookingData) => {
  request({
    method: "post",
    url: config.apiHost + "/webhooks/bookings/creation-reserve",
    headers: {
      "Content-Type": "application/json"
    },
    data: bookingData
  })
    .then(success)
    .catch(fail);
};

const joinActivity = (state, success, fail, activityData) => {
  request({
    method: "post",
    url: config.apiHost + "/webhooks/activities/join-activity",
    headers: {
      "Content-Type": "application/json"
    },
    data: activityData
  })
    .then(success)
    .catch(fail);
};

const joinActivityFromPWA = (state, success, fail, activityData) => {
  request({
    method: "post",
    url:
      config.mobileApiHost +
      "/v1/illbethere/join?userkey=" +
      activityData.userkey +
      "&apikey=" +
      config.apiKey +
      "&establishment=" +
      activityData.establishmentId,
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      service: parseInt(activityData.serviceId),
      day: activityData.date,
      hour: activityData.hour
    }
  })
    .then(success)
    .catch(fail);
};

const getForm = (state, success, fail, data) => {
  if (state.apiKey && state.apiKey.length > 0 && state.apiKey !== config.apiKey) {
    config.apiKey = state.apiKey;
  }

  const params = new URLSearchParams({
    userkey: state.user.userKey,
    apikey: config.apiKey,
    devicekey: state.user.deviceKey,
    establishment: state.establishment.publicId
  });

  request({
    method: "post",
    url: `${config.mobileApiHost}/v1/bookings/fields?${params.toString()}`,
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      service: data.serviceId
    }
  })
    .then(success)
    .catch(fail);
};

const sendCommentToTransaction = (state, success, fail, data) => {
  if (state.apiKey && state.apiKey.length > 0 && state.apiKey !== config.apiKey) {
    config.apiKey = state.apiKey;
  }
  request({
    method: "post",
    url:
      config.mobileApiHost +
      "/v1/requests/itemType/" +
      data.itemType +
      "/itemId/" +
      data.itemId +
      "?userkey=" +
      state.user.userKey +
      "&apikey=" +
      config.apiKey +
      "&establishment=" +
      state.establishment.publicId +
      "&devicekey=" +
      state.user.deviceKey,
    headers: { "Content-Type": "application/json" },
    data: {
      comment: data.comment,
      botResponse: data.botResponse
    }
  })
    .then(success)
    .catch(fail);
};

const getTransactionDetail = (state, success, fail, { serviceType, itemType, itemId, serviceObj }) => {
  const locale = (window.navigator.language ?? state.user.locale).substring(0, 2);
  const url = `${config.mobileApiHost}/v1/requests/${serviceType}/${serviceObj?.publicId}/request/itemType/${itemType}/itemId/${itemId}?translatefields=true&language=${locale}&userkey=${state.user.userKey}&apikey=${config.apiKey}&establishment=${state.establishment.publicId}&devicekey=JWT`;

  request({
    method: "get",
    url: url,
    cache: false
  })
    .then(success)
    .catch(fail);
};

const cancelBooking = (state, success, fail, data) => {
  const url =
    config.mobileApiHost +
    "/v1/bookings/cancel" +
    "?userkey=" +
    state.user.userKey +
    "&apikey=" +
    config.apiKey +
    "&establishment=" +
    state.establishment.id +
    "&devicekey=" +
    state.user.deviceKey;

  request({
    method: "post",
    url: url,
    headers: {
      "Content-Type": "application/json"
    },
    params: {},
    data: data
  })
    .then(success)
    .catch(fail);
};

const externalWebBooking = (state, success, fail, data) => {
  const bearer = auth.getAuthBearer(state);
  const url = config.clusterHost + "/tm/external/funnel";

  const body = {
    establishmentId: state.establishment?.id + "",
    lang: window.navigator.language.substring(0, 2)
  };
  const room = state.user?.room;
  if (room) {
    body["room"] = room;
  }
  const uid = state.user?.uid;
  if (uid) {
    body["userId"] = uid + "";
  }
  if (
    state.user?.establishments &&
    state.establishment?.id &&
    state.user?.establishments[state.establishment?.id] != null
  ) {
    const cliId = state.user?.establishments[state.establishment?.id].cliId;
    if (cliId) {
      body["cliId"] = cliId + "";
    }
    const stayId = state.user?.establishments[state.establishment?.id].stayId;
    if (stayId) {
      body["stayId"] = stayId + "";
    }
  }

  if (data.service) {
    body.service = data.service;
  }

  if (data.externalId) {
    body.externalId = data.externalId;
  }

  request({
    method: "post",
    url: url,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearer
    },
    params: {},
    data: body
  })
    .then(success)
    .catch(fail);
};

export {
  reserveProduct,
  reserveService,
  joinActivity,
  joinActivityFromPWA,
  getForm,
  sendCommentToTransaction,
  getTransactionDetail,
  cancelBooking,
  externalWebBooking
};
